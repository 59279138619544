import { useQuery } from "@tanstack/react-query";
import { create, windowScheduler, keyResolver } from "@yornaath/batshit";
import apiClient from "@/modules/request.module.v2";

const usersBadger = create({
	fetcher: async (ids) => {
		const response = await apiClient.post("/users/public/users", ids);
		return response.data;
	},
	resolver: keyResolver("id"),
	scheduler: windowScheduler(10),
});

export default (userId, queryOptions = {}) => {
	return useQuery({
		queryKey: ["publicUser", userId],
		queryFn: async () => {
			return usersBadger.fetch(userId);
		},
		staleTime: Infinity,
		...queryOptions,
	});
};
