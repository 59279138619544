import * as React from "react";

import { string, bool, oneOfType, arrayOf } from "prop-types";

import { cn } from "@/components/utils";
import { LoaderCircle } from "lucide-react";

const Input = React.forwardRef(
	({ className, type, isLoading, error, ...props }, ref) => {
		const renderError = (error) =>
			error && <p className="text-destructive text-sm">{error}</p>;

		return (
			<div className="relative">
				<input
					type={type}
					className={cn(
						"flex h-10 w-full rounded-sm border border-input bg-background px-4 py-7 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-accent disabled:border-black/40",
						error && "border-destructive",
						className,
					)}
					ref={ref}
					{...props}
				/>
				{isLoading && (
					<div className="w-full h-full top-0 absolute flex items-center justify-end pe-2 pointer-events-none text-muted-foreground">
						<LoaderCircle size={16} className="animate-spin" />
					</div>
				)}
				{Array.isArray(error)
					? error.map((err) => renderError(err))
					: renderError(error)}
			</div>
		);
	},
);

Input.propTypes = {
	className: string,
	type: string,
	isLoading: bool,
	error: oneOfType([string, arrayOf(string)]),
};

Input.displayName = "Input";

export { Input };
