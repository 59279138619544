// Created January 2024 to replace "./request.module.js"
// Intented to be used with react-query

import axios from "axios";
import Cookies from "universal-cookie";

const axiosInstance = axios.create({
	baseURL: "/api",
	timeout: 300000,
});

axiosInstance.interceptors.request.use((config) => {
	const accessToken = new Cookies().get("accessToken");
	const publicAccessToken = localStorage.getItem("publicAccessToken");

	if (config?.params?.companyId) {
		config.headers["x-company-id"] = config.params.companyId;
	}

	if (accessToken) {
		config.headers["authorization"] = `Bearer ${accessToken}`;
	}

	if (location.pathname.includes("/public") && publicAccessToken) {
		config.headers["authorization"] = `Bearer ${localStorage.getItem(
			"publicAccessToken",
		)}`;
	}

	return config;
});

export default axiosInstance;
