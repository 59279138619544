import React from "react";
import styled from "styled-components";
import { func } from "prop-types";

const StyledToolbarWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const ShareholdersToolbarControl = ({
	addCapitalInsuranceRenderer,
	unlinkDropdownRenderer,
	moreActionsDropdownRenderer,
	shareholderPeekDropdownRenderer,
}) => {
	return (
		<StyledToolbarWrapper>
			{shareholderPeekDropdownRenderer && shareholderPeekDropdownRenderer()}
			{addCapitalInsuranceRenderer && addCapitalInsuranceRenderer()}
			{unlinkDropdownRenderer && unlinkDropdownRenderer()}
			{moreActionsDropdownRenderer && moreActionsDropdownRenderer()}
		</StyledToolbarWrapper>
	);
};

ShareholdersToolbarControl.propTypes = {
	addCapitalInsuranceRenderer: func,
	unlinkDropdownRenderer: func,
	moreActionsDropdownRenderer: func,
	shareholderPeekDropdownRenderer: func,
};

export default ShareholdersToolbarControl;
