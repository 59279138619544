import { useQuery } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";

export default () => {
	const companyId = useCompanyIdFromUrl();

	const query = useQuery({
		queryKey: ["canSetUpSharebook", companyId],
		queryFn: () => {
			return apiClient.get(`/shares/transactions/is-allowed/set-up-sharebook`, {
				params: { companyId },
			});
		},
		retry: false,
	});

	if (query.isLoading) return null;
	if (query.data?.status !== 200) return false;
	return true;
};
