import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentUploadContainer from "./documents-upload.container";
import FoldersContainer from "./folders.container";
import RightViewContainer from "./right-view.container";
import MoveDocumentModalContainer from "./documents-move-file.container";
import CopyDocumentToPersonalDocumentsModal from "./copy-document-to-personal-documents.container";
import DocumentsSettingsBar from "./document-settings-bar.container";
import InitDocumentStructureOnboardingModal from "../notifications/onboarding/modals/documents/init-document-structure.container";
import DocumentsDetails from "./document-details.container";
import ConfirmContainer from "../shared/confirm.container";
import AddDocumentToCollectionModalContainer from "./add-document-to-collection-modal.container";
import AddExistingDocumentToCollectionWarningModalContainer from "./add-existing-document-to-collection-warning-modal.container";
import {
	CONFIRM_DELETE_DOCUMENT,
	ADD_DOCUMENT_TO_COLLECTION_MODAL,
	ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL,
	CONFIRM_DELETE_SIGNED_DOCUMENT,
	CONFIRM_DELETE_DOCUMENT_SIGNING_PROCESS_ONGOING,
	DOCUMENTS_ADVANCED_FILTER_MODAL,
} from "../../constants/modals";
import { closeModal } from "../../actions/modals.actions";
import { routeHoc } from "../../interfaces/router";
import {
	LOCATION_COMPANY_DOCUMENTS,
	HELPSCOUT_DOCUMENTS_SECTION,
} from "/shared/constants";
import { getQuery } from "../../interfaces/history";
import DocumentsFilterDialogButton from "../../dumb-components/documents/dialog/documents-filter-dialog-button";
import AdvancedFilterDialog from "../../dumb-components/documents/dialog/advanced-filter-dialog";
import DocumentListGrid from "../../dumb-components/documents/grid/document-list-grid";
import Box from "@mui/material/Box";
import DocumentsSearch from "./documents-search";
import InfoSidebar from "../../dumb-components/helpscout/info-sidebar";
import Text from "../../dumb-components/shared/text/text";

const Documents = ({ location, match, history }) => {
	const dispatch = useDispatch();
	const gridRef = useRef(null);
	const modalOptions = useSelector((state) =>
		state.modals.getIn(["activeModal", "options"]),
	);
	const isAddDocumentToCollectionModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			ADD_DOCUMENT_TO_COLLECTION_MODAL,
	);
	const isAddExistingDocumentToCollectionWarningModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL,
	);
	const activeModal = useSelector((state) =>
		state.modals.getIn(["activeModal", "name"]),
	);
	const isDocumentsAdvancedFilterModalOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) ===
			DOCUMENTS_ADVANCED_FILTER_MODAL,
	);

	let isConfirmModalOpen;
	let confirmModlaMessageTid;

	switch (activeModal) {
		case CONFIRM_DELETE_DOCUMENT:
			confirmModlaMessageTid = [
				{
					tid: "document.delete_document_alert.message.linked_document.part_one",
				},
				{
					tid: "document.delete_document_alert.message.linked_document.part_two",
					values: {
						deleteTid: (
							<Text
								tid="document.delete_document_alert.message.linked_document.part_two.delete"
								bold={700}
							/>
						),
					},
				},
			];
			isConfirmModalOpen = true;
			break;
		case CONFIRM_DELETE_SIGNED_DOCUMENT:
			confirmModlaMessageTid =
				"document.delete_document_alert.message.signed_document";
			isConfirmModalOpen = true;
			break;
		case CONFIRM_DELETE_DOCUMENT_SIGNING_PROCESS_ONGOING:
			confirmModlaMessageTid =
				"document.delete_document_alert.message.signing_process_ongoing";
			isConfirmModalOpen = true;
			break;
		default:
			isConfirmModalOpen = false;
			break;
	}

	const getComponentBasePath = () => {
		const { pathname } = location;
		const path = pathname;
		const pathArray = path.split("documents");
		return pathArray[0] + "documents";
	};

	const renderDocumentsLeftHeader = () => {
		const querystr = location.search;
		const basePath = getComponentBasePath();
		const query = getQuery();

		return (
			<div className="flex h-16">
				<DocumentsSearch gridRef={gridRef} />
				<DocumentUploadContainer
					basePath={basePath}
					querystr={querystr}
					isSharedFromCompanyId={query.isSharedFromCompanyId}
				/>
				<DocumentsFilterDialogButton />
			</div>
		);
	};

	const renderRightSplitView = () => {
		const basePath = getComponentBasePath();

		return (
			<RightViewContainer
				location={location}
				params={match.params}
				basePath={basePath}
				disableHelpscout
				helpscout={
					<InfoSidebar
						article={HELPSCOUT_DOCUMENTS_SECTION}
						link
						linkTid={"documents.section.helpscout.sidebar.link.about"}
					/>
				}
			>
				{match.params.id && (
					<DocumentsDetails
						match={match}
						location={location}
						history={history}
					/>
				)}
			</RightViewContainer>
		);
	};

	const renderHeader = () => {
		const { params } = match;
		const basePath = getComponentBasePath();

		return (
			<Box>
				<FoldersContainer basePath={basePath} params={params} />
				<DocumentsSettingsBar />
			</Box>
		);
	};

	const querystr = location.search;
	const basePath = getComponentBasePath();

	return (
		<div className="flex flex-col h-full pb-0 p-md">
			{renderHeader()}
			<div className="flex w-full gap-6 overflow-auto flex-1">
				<div className="flex flex-1 max-w-screen-lg">
					<div className="flex flex-col flex-1 gap-6">
						<div>{renderDocumentsLeftHeader()}</div>
						<div className="flex flex-1 pb-12 items-start">
							<DocumentListGrid
								basePath={basePath}
								querystr={querystr}
								gridRef={gridRef}
								documentId={match.params.id}
							/>
						</div>
					</div>
				</div>
				{match.params.id && (
					<div className="flex flex-1">{renderRightSplitView()}</div>
				)}
			</div>

			<MoveDocumentModalContainer />
			<CopyDocumentToPersonalDocumentsModal />
			<InitDocumentStructureOnboardingModal />
			<ConfirmContainer
				title="documents.delete_document_alert.title"
				message={confirmModlaMessageTid}
				onConfirm={modalOptions}
				onDecline={() => dispatch(closeModal())}
				confirmBtnTid="document.delete_document_alert.footer.btn.confirm"
				declineBtnTid="document.delete_document_alert.footer.btn.decline"
				isOpen={isConfirmModalOpen}
			/>
			{isAddDocumentToCollectionModalOpen && (
				<AddDocumentToCollectionModalContainer />
			)}
			{isAddExistingDocumentToCollectionWarningModalOpen && (
				<AddExistingDocumentToCollectionWarningModalContainer />
			)}
			{isDocumentsAdvancedFilterModalOpen && <AdvancedFilterDialog />}
		</div>
	);
};

export default routeHoc(Documents, LOCATION_COMPANY_DOCUMENTS);
