import React from "react";
import { string } from "prop-types";
import usePublicUser from "@/hooks/users/usePublicUser";
import { User } from "lucide-react";
import { cn } from "./utils";

const getInitials = (name) => {
	const names = name.split(" ");
	const firstName = names.shift();
	const lastName = names.pop();

	return `${firstName.charAt(0)}${
		lastName ? lastName.charAt(0) : ""
	}`.toUpperCase();
};

const CLASS_NAMES = {
	0: "bg-red-300 text-red-800",
	1: "bg-green-300 text-green-800",
	2: "bg-blue-300 text-blue-800",
	3: "bg-orange-300 text-orange-900",
	4: "bg-yellow-300 text-yellow-700",
	5: "bg-purple-300 text-purple-800",
	6: "bg-pink-300 text-pink-800",
	7: "bg-indigo-300 text-indigo-800",
	8: "bg-cyan-300 text-cyan-700",
	9: "bg-teal-200 text-teal-800",
	a: "bg-fuchsia-300 text-fuchsia-800",
	b: "bg-amber-600 text-amber-200",
	c: "bg-rose-900 text-rose-200",
	d: "bg-violet-800 text-violet-200",
	e: "bg-emerald-800 text-emerald-200",
	f: "bg-gray-700 text-gray-200",
};

const UserAvatar = ({ userId, userName, className }) => {
	const { data: user } = usePublicUser(userId);
	const name = user?.name ?? userName;

	return (
		<span
			className={cn(
				"relative flex h-16 w-16 shrink-0 overflow-hidden rounded-full",
				className,
			)}
		>
			{user?.image?.filename ? (
				<img
					src={`/api/users/public/images/${user.image.filename}-80x80?userId=${userId}`}
				/>
			) : (
				<div
					className={cn(
						"flex h-full w-full justify-center items-center font-bold",
						CLASS_NAMES[userId?.charAt(0)] || CLASS_NAMES.f,
					)}
				>
					{name ? getInitials(name) : <User className="text-input" size={20} />}
				</div>
			)}
		</span>
	);
};

UserAvatar.propTypes = {
	userId: string,
	userName: string,
};

export default UserAvatar;
