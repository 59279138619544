import React, { PureComponent } from "react";
import { node, object } from "prop-types";
import styled from "styled-components";

const StyledWrapper = styled.div`
	position: relative;
`;

const EditorWrapper = styled.div`
	border: 1px solid ${(props) => props.theme.colors.border};
	border-radius: ${(props) => props.theme.spacing[4]};
	padding: ${(props) => props.theme.spacing[4]};
`;

const StyledToolbar = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.theme.spacing[2]};
	justify-content: flex-end;
	align-items: center;
`;

export default class HandleEmailTemplate extends PureComponent {
	static propTypes = {
		languageSwitcherComponent: object,
		dropdownComponent: object.isRequired,
		editorComponent: object.isRequired,
		subjectFieldComponent: node,
	};

	static defaultProps = {};

	render = () => {
		const {
			languageSwitcherComponent,
			dropdownComponent,
			editorComponent,
			subjectFieldComponent,
		} = this.props;

		return (
			<StyledWrapper>
				<StyledToolbar>
					{languageSwitcherComponent}
					{dropdownComponent}
				</StyledToolbar>
				{subjectFieldComponent}
				<EditorWrapper>{editorComponent}</EditorWrapper>
			</StyledWrapper>
		);
	};
}
