import React, { PureComponent } from "react";
import { bool, func, string } from "prop-types";
import OutdatedBrowserFullscreen from "../dmz/outdated-browser-fullscreen";
import CredentialsCarousel from "./credentialsCarousel";
import LanguagePickerDropdown from "@/components/languagePickerDropdown";

export default class Credentials extends PureComponent {
	static propTypes = {
		section: string,
		isValidBrowser: bool,
		ignoreBrowserVersion: bool,
		onIgnoreBrowserVersion: func,
		renderMiddleComponent: func,
		renderBottomComponent: func,
	};

	render = () => {
		const {
			section,
			isValidBrowser,
			ignoreBrowserVersion,
			onIgnoreBrowserVersion,
			renderMiddleComponent,
			renderBottomComponent,
		} = this.props;

		if (!isValidBrowser && !ignoreBrowserVersion) {
			return (
				<div className="flex w-full h-full">
					<OutdatedBrowserFullscreen
						onIgnoreBrowserVersion={onIgnoreBrowserVersion}
					/>
				</div>
			);
		}

		return (
			<div className="flex w-full h-full">
				<div className="w-full h-full lg:drop-shadow lg:max-w-[1024px] lg:max-h-[768px] lg:m-auto lg:rounded-[20px] lg:bg-background lg:p-4 relative">
					<div className="flex justify-center items-center absolute top-0 right-0">
						<LanguagePickerDropdown className="border-none m-4 rounded-tr-2xl" />
					</div>
					<div className="flex h-full">
						<div className="hidden bg-contrast text-background text-lg h-full w-[50%] bg-invono-logo bg-no-repeat bg-[right_50px_top_28px] bg-[length:200px] lg:rounded-[12px] sm:flex flex-col">
							<CredentialsCarousel />
						</div>
						<div className="flex flex-col p-10 flex-1 scrollbox w-[50%] sm:p-20">
							{renderMiddleComponent?.(section)}
							{renderBottomComponent?.(section)}
						</div>
					</div>
				</div>
			</div>
		);
	};
}
