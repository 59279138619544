import React from "react";
import { node } from "prop-types";
import { cn } from "./utils";

const Separator = ({ children, size }) => (
	<div
		className={cn("flex items-center gap-2 w-full", {
			"my-6": size === "default",
			"my-4": size === "small",
		})}
	>
		<div className="flex-1 border-t h-[1px]"></div>
		<div className="text-input flex gap-1 items-center">{children}</div>
		<div className="flex-1 border-t h-[1px]"></div>
	</div>
);

Separator.propTypes = {
	children: node,
};

Separator.defaultProps = {
	size: "default",
};

export default Separator;
