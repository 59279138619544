import React, { useState } from "react";
import { List, Map, fromJS, merge } from "immutable";
import moment from "../../modules/moment.module";
import SubscriptionCheckoutModal from "../../components/subscriptions/subscriptionCheckoutModal";
import { Trans, useTranslation } from "react-i18next";

import PlansPanel from "../../dumb-components/subscriptions/plans-panel";

import {
	PRODUCT_ACCOUNT_PLAN_FREE_ID,
	SUBSCRIPTIONS_PLAN_TYPE_ACCOUNT,
} from "/shared/constants";

import useUpdateSubscription from "@/hooks/useUpdateSubscription";
import useDialogModal from "@/hooks/useDialogModal";
import useCompanyFromUrl from "@/hooks/useCompanyFromUrl";
import useChangeSubscriptionPlan from "@/hooks/useChangeSubscriptionPlan";

function PlansContainer(props) {
	const { t } = useTranslation();

	const { subscription, plans, documentsSpace, usedStorageSpace } = props;

	const currentAccountPlanId = subscription.accountPlanId;

	const { data: companyRequest } = useCompanyFromUrl();
	const company = companyRequest?.data;

	const companyDueForCancellation = company?.toBeCanceled;
	const companyId = company?.id;

	const { mutate: updateSubscriptionPlan } =
		useChangeSubscriptionPlan(companyId);

	const [showCheckout, setShowCheckout] = useState(false);
	const [selectedAccountPlanId, setSelectedAccountPlanId] = useState();

	const { mutateAsync: updateSubscription } = useUpdateSubscription(companyId);

	const isFreeAccountPlanSelected =
		selectedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID;

	const { confirm, alert, dialogElement } = useDialogModal();

	const convertAccountPlansToSelectOptions = (plansJS, freePlanId) => {
		const plans = fromJS(plansJS);
		let options = List();
		let optiongroups = List();
		const freePlan = plans.get("month").find((p) => p.get("id") === freePlanId);
		options = options.push(freePlan);

		plans.forEach((items, interval) => {
			const filteredItems = items
				.filter(
					(i) =>
						i.get("id") !== freePlanId &&
						i.getIn(["metadata", "active"]) !== "false",
				)
				.sort(sortByLevel);
			const optiongroup = Map({
				label: `subscriptions.plan_otions.optiongroup.${interval}`,
				options: List(filteredItems),
			});
			optiongroups = optiongroups.push(optiongroup);
		});

		optiongroups = optiongroups.sort((a, b) =>
			a.get("label").localeCompare(b.get("label")),
		);
		options = merge(options, optiongroups);

		return options;
	};

	const isDowngrading = () => {
		// No current subscription so we are not downgrading
		if (!currentAccountPlanId) {
			return false;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}

		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];

		// We are downgrading if level of the current account plan is greater than the selected one
		if (
			parseInt(currentAccountPlan?.metadata?.level) >
			parseInt(selectedAccountPlan?.metadata?.level)
		) {
			return true;
		}

		return false;
	};

	const isDowngradingAllowed = () => {
		// No current subscription so accept
		if (!currentAccountPlanId) {
			return true;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}

		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];

		const newStorageSpace = parseFloat(selectedAccountPlan?.metadata?.storage);
		// We are downgrading if level of the current account plan is greater than the selected one
		// but the company have used too much storage space to allow downgrading.
		if (
			parseInt(currentAccountPlan?.metadata?.level) >
				parseInt(selectedAccountPlan?.metadata?.level) &&
			usedStorageSpace > newStorageSpace
		) {
			return false;
		}

		return true;
	};

	const showMessageAboutFolderSharing = () => {
		const { subscription, plans, isSharingFolders } = props;
		const currentAccountPlanId = subscription.accountPlanId;

		if (!currentAccountPlanId) {
			return false;
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false;
		}
		const currentAccountPlan = plans.mapPlanIdToPlan?.[currentAccountPlanId];
		const selectedAccountPlan = plans.mapPlanIdToPlan?.[selectedAccountPlanId];
		const folderSharingEnabledForCurrentAccountPlan =
			currentAccountPlan?.metadata?.folderSharingEnabled === "true";
		const folderSharingEnabledForSelectedAccountPlan =
			selectedAccountPlan?.metadata?.folderSharingEnabled === "true";

		if (
			folderSharingEnabledForCurrentAccountPlan &&
			!folderSharingEnabledForSelectedAccountPlan &&
			isSharingFolders
		) {
			return true;
		}

		return false;
	};

	const onSaveSubscription = () => {
		updateSubscription({});
	};

	const onClickPurchase = async () => {
		if (!isDowngrading()) return setShowCheckout(true);

		if (!isDowngradingAllowed()) {
			return await alert(
				t("subscriptions.alert_downgrading.title"),
				<Trans i18nKey="subscriptions.alert_downgrading.question" />,
				{ variant: "warning" },
			);
		}

		if (isFreeAccountPlanSelected) {
			return (
				(await confirm(
					t("subscriptions.button.cancel_subscription"),
					t("subscriptions.cancel_subscription_confirm.message"),
					{
						variant: "destructive",
						submitText: t("subscriptions.button.cancel_subscription"),
					},
				)) &&
				updateSubscriptionPlan({ planIds: [PRODUCT_ACCOUNT_PLAN_FREE_ID] })
			);
		}

		setShowCheckout(true);
	};

	const sortByLevel = (a, b) => {
		return (
			(parseInt(a?.metadata?.level) || 0) - (parseInt(b?.metadata?.level) || 0)
		);
	};

	if (!subscription || !plans) {
		return null;
	}

	const isFreeOfCharge = subscription?.isFreeOfCharge;

	const subscribedAccountPlanId =
		subscription?.accountPlanId ?? PRODUCT_ACCOUNT_PLAN_FREE_ID;

	const subscribedAccountPlan =
		plans["mapPlanIdToPlan"]?.[subscribedAccountPlanId];
	const willChangeToAccountPlan = subscription?.willChangeToAccountPlan;

	const selectedAccountPlan =
		selectedAccountPlanId && plans["mapPlanIdToPlan"]?.[selectedAccountPlanId];

	const stripeData = subscription.stripeData;

	const currentPeriodEnd =
		stripeData?.subscriptions?.data?.[0]?.current_period_end;

	const nextBillingDate =
		currentPeriodEnd && moment.unix(currentPeriodEnd).format("LL");

	//If you have a free account you should not be allowed to have a storage plan.

	const willHaveFreeAccountPlan =
		(selectedAccountPlanId &&
			selectedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID) ||
		(!selectedAccountPlanId &&
			subscribedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID);

	let accountPlans = plans[SUBSCRIPTIONS_PLAN_TYPE_ACCOUNT];

	const confirmPurchaseMessages = [
		showMessageAboutFolderSharing() &&
			t("subscriptions.confirm_purchase.question.sharing_folders"),
	].filter(Boolean);

	return (
		<React.Fragment>
			<PlansPanel
				accountPlans={convertAccountPlansToSelectOptions(
					accountPlans,
					PRODUCT_ACCOUNT_PLAN_FREE_ID,
				)}
				selectedAccountPlanId={
					selectedAccountPlanId ||
					willChangeToAccountPlan ||
					subscribedAccountPlanId
				}
				selectedAccountPlan={selectedAccountPlan}
				subscribedAccountPlan={subscribedAccountPlan}
				isLoading={showCheckout}
				nextBillingDate={nextBillingDate}
				isFreeOfCharge={isFreeOfCharge}
				disabled={
					isFreeOfCharge || willChangeToAccountPlan || companyDueForCancellation
				}
				onSelectAccountPlan={setSelectedAccountPlanId}
				onClickPurchase={onClickPurchase}
				onSaveSubscription={onSaveSubscription}
				companyDueForCancellation={companyDueForCancellation}
				willHaveFreeAccountPlan={willHaveFreeAccountPlan}
				documentsSpace={documentsSpace}
				usedStorageSpace={usedStorageSpace}
			/>
			{showCheckout && (
				<SubscriptionCheckoutModal
					confirmationMessageRenderer={() =>
						confirmPurchaseMessages.map((message) => (
							<p key={message}>{message}</p>
						))
					}
					onCancel={() => setShowCheckout(null)}
					selectedAccountPlanId={selectedAccountPlanId}
					onPurchaseComplete={() => {
						window.location.reload();
					}}
				/>
			)}
			{dialogElement}
		</React.Fragment>
	);
}

export default PlansContainer;
