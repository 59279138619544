import React from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { ButtonTransparentIcon } from "../../dumb-components/shared/button";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";
import { createInvestor } from "../../actions/investors.actions";
import history from "../../interfaces/history";

const BTN_STATES = {
	default: {
		tid: "tooltip.shares.actions.add_shareholder",
	},
	alreadyActive: {
		tid: "tooltip.shares.shareholders.action.add_shareholder.already_active",
		delayShow: "instant",
	},
	hasAppliedFilters: {
		tid: "tooltip.shares.shareholders.action.add_shareholder.active_filters",
		delayShow: "instant",
	},
};

const ShareholdersAdvancedFilterBtnContainer = ({
	basePath,
	onClick,
	createInvestor,
	history,
	hasAppliedFilters,
	location,
}) => {
	const handleOnClickNewInvestor = () => {
		onClick && onClick();
		createInvestor({}, (e, investor) => {
			if (!e) {
				history.push(`${basePath}/${investor.get("id")}`);
			}
		});
	};

	const isAlreadyActive = location.pathname.endsWith("/new");
	let btnActiveState = null;

	if (hasAppliedFilters) {
		btnActiveState = "hasAppliedFilters";
	} else if (isAlreadyActive) {
		btnActiveState = "alreadyActive";
	}

	return (
		<Tooltip states={BTN_STATES} activeState={btnActiveState}>
			<ButtonTransparentIcon
				icon="faUserPlus"
				size="sml"
				onClick={handleOnClickNewInvestor}
				disabled={hasAppliedFilters || isAlreadyActive}
			/>
		</Tooltip>
	);
};

ShareholdersAdvancedFilterBtnContainer.propTypes = {
	basePath: string,
};

const mapStoreToProps = (store) => {
	return {
		history: history,
		hasAppliedFilters: store.investors.get("hasAppliedFilters"),
	};
};

const mapActionsToProps = {
	createInvestor,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(ShareholdersAdvancedFilterBtnContainer);
