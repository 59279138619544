import React from "react";

import {
	DropdownMenu as BaseDropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { cn } from "./utils";

const DropdownMenu = ({ trigger, options, onSelect }) => (
	<BaseDropdownMenu modal={false}>
		<DropdownMenuTrigger>{trigger}</DropdownMenuTrigger>
		{options && (
			<DropdownMenuContent
				onClick={(evt) => {
					evt.stopPropagation(); // Prevents undesired triggers when used inside a clickable parent
				}}
			>
				{options.map((option) => {
					if (option.label === "---") return <DropdownMenuSeparator />;

					if (option.label && !option.value && !option.onClick)
						return <DropdownMenuLabel>{option.label}</DropdownMenuLabel>;

					if (option.label && (option.value || option.onClick))
						return (
							<DropdownMenuItem
								key={option.value}
								className={cn("[&_svg]:mr-3 [&_svg]:h-6 [&_svg]:w-6", {
									"cursor-pointer":
										!option.disabled && typeof option.onClick === "function",
								})}
								onClick={
									option.onClick ?? ((evt) => onSelect?.(option.value, evt))
								}
								disabled={option.disabled}
								title={option.title}
							>
								{option.icon}
								{option.label}
							</DropdownMenuItem>
						);
				})}
			</DropdownMenuContent>
		)}
	</BaseDropdownMenu>
);

export default DropdownMenu;
