import { isImmutable } from "immutable";

const obj = {
	getTranslatedValue: (obj, userLang, region) => {
		if (!obj || typeof obj === "string" || !userLang || !region) {
			return obj;
		}

		userLang = "sv";
		let value;

		if (isImmutable(obj)) {
			value = obj.get(userLang, obj.get(region.language.main));

			if (!value || value === "") {
				value = obj.get(region.language.main);
			}

			return value;
		}

		value = obj[userLang] || obj[region.language.main];

		if (!value || value === "") {
			value = obj[region.language.main];
		}

		return value;
	},
	getBrowserLanguage: () => {
		let browserLanguage = navigator.languages
			? navigator.languages[0]
			: navigator.language;

		browserLanguage = browserLanguage.toLowerCase();

		if (browserLanguage.startsWith("en-") || browserLanguage === "en") {
			browserLanguage = "en-GB";
		}

		if (browserLanguage === "sv" || browserLanguage === "sv-se") {
			browserLanguage = "sv-SE";
		}

		return browserLanguage;
	},
	/**
	 *
	 * @param {string} language
	 * @returns {string} 'en-GB' | 'sv-SE'
	 */
	normalizeLanguage: (language) => {
		if (!language) {
			throw "No language given";
		}

		return language.startsWith?.("sv") ? "sv-SE" : "en-GB";
	},
};

export default obj;
