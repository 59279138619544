import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, bool } from "prop-types";
import { Map } from "immutable";
import { EditorState, SelectionState } from "draft-js";
import CommentCreator from "../../dumb-components/shared/comments/comment-creator";
import { addComment } from "../../actions/comments.actions";
import { v1 } from "uuid";

class CommentsCreatorContainer extends Component {
	constructor(props) {
		super(props);
		this.rteRef = React.createRef();
	}

	state = {
		editorState: Map(),
		isFocused: false,
	};

	static propTypes = {
		onCommentCancel: func,
		onCommentCreated: func,
		objId: string,
		objType: string.isRequired,
		objTitle: string,
		objUrl: string,
		isDisabled: bool,
		placeholderTid: string,
		btnTid: string,
	};

	static defaultProps = {
		placeholderTid: "comments.comment_creator.placeholder",
		btnTid: "comments.creator.save_comment",
	};

	componentDidMount = () => {
		const { objId } = this.props;

		if (objId) {
			this.setState((prevState) => {
				return {
					editorState: prevState.editorState.set(
						objId,
						EditorState.createEmpty(),
					),
				};
			});
		}
	};

	componentDidUpdate = (prevProps) => {
		const { objId } = this.props;
		const { editorState } = this.state;

		if (prevProps.objId !== objId && !editorState.has(objId)) {
			this.setState({
				editorState: editorState.set(objId, EditorState.createEmpty()),
			});
		}
	};

	getEditorState = () => {
		const { objId } = this.props;
		const { editorState } = this.state;

		return editorState.get(objId, EditorState.createEmpty());
	};

	onChange = (editorState) => {
		const { objId } = this.props;

		this.setState((prevState) => {
			return { editorState: prevState.editorState.set(objId, editorState) };
		});
	};

	onShouldFocus = () => {
		const { objId } = this.props;
		const { isFocused } = this.state;
		let editorState = this.state.editorState.get(objId);

		if (isFocused) {
			this.rteRef.focusEditor();
			return;
		}

		this.setState({ isFocused: true });

		this.rteRef.focusEditor();

		editorState = this.moveSelectionToEnd(editorState);
		this.onChange(editorState);
	};

	onCreatorFocus = () => {
		const { objId } = this.props;
		let editorState = this.state.editorState.get(objId);

		this.setState({ isFocused: true });

		this.rteRef.focusEditor();

		editorState = this.moveSelectionToEnd(editorState);
		this.onChange(editorState);
	};

	onCreatorBlur = () => {
		const editorState = this.getEditorState();

		if (
			editorState &&
			editorState.getCurrentContent() &&
			!editorState.getCurrentContent().getPlainText()
		) {
			this.setState((prevState) => {
				return { ...prevState, isFocused: false };
			});
		}
	};

	onCommentSave = () => {
		const {
			objType,
			objId,
			addComment,
			userObj,
			onCommentCreated,
			objTitle,
			objUrl,
		} = this.props;
		const editorState = this.state.editorState.get(objId);

		const text = editorState.getCurrentContent();
		const commentId = v1();

		const newComment = Map({
			id: commentId,
			text,
			createdBy: userObj.get("id"),
		});

		// Send action
		addComment(objType, objId, newComment, objTitle, objUrl);

		this.setState((prevState) => {
			return {
				...prevState,
				isFocused: false,
				editorState: prevState.editorState.set(
					objId,
					EditorState.createEmpty(),
				),
			};
		});

		onCommentCreated && onCommentCreated(objType, objId, commentId);
	};

	moveSelectionToEnd = (editorState) => {
		const content = editorState.getCurrentContent();
		const blockMap = content.getBlockMap();

		const key = blockMap.last().getKey();
		const length = blockMap.last().getLength();

		const selection = new SelectionState({
			anchorKey: key,
			anchorOffset: length,
			focusKey: key,
			focusOffset: length,
		});
		return EditorState.forceSelection(editorState, selection);
	};

	render = () => {
		const { userObj, isDisabled, placeholderTid, btnTid } = this.props;
		const { isFocused } = this.state;
		const image = userObj.get("image");
		const id = userObj.get("id");
		const currentUserImg = image
			? `/api/users/public/images/${image.get("filename")}-80x80?userId=${id}`
			: null;
		const editorState = this.getEditorState();

		return (
			<CommentCreator
				user={userObj.toJS()}
				editorState={editorState}
				onChange={this.onChange}
				onShouldFocus={this.onShouldFocus}
				onRef={(ref) => (this.rteRef = ref)}
				onFocus={this.onCreatorFocus}
				onBlur={this.onCreatorBlur}
				onCommentSave={this.onCommentSave}
				isFocused={isFocused}
				userImage={currentUserImg}
				isDisabled={isDisabled}
				placeholderTid={placeholderTid}
				btnTid={btnTid}
			/>
		);
	};
}
function mapStoreToProps(store) {
	return {
		userObj: store.user.get("userObj", Map()) || Map(),
	};
}

const mapActionsToProps = {
	addComment,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(CommentsCreatorContainer);
