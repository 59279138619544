import React, { Component } from "react";
import { connect } from "react-redux";
import {
	deleteLastTransaction,
	cancelTemporaryTransactions,
} from "../../../../actions/transaction.actions";
import { listInvestors } from "../../../../actions/investors.actions";
import Moment from "../../../../modules/moment.module";
import { fetchMenu } from "../../../../actions/menu.actions";
import req from "../../../../modules/request.module";
import history from "../../../../interfaces/history";
import { Button } from "@/components/ui/button";
import { Trash2, Undo2 } from "lucide-react";
import i18n from "@/i18n";
import Panel from "@/dumb-components/shared/panel/panel";
import withResolvedProps from "@/hocs/withResolvedProps";
import useDialogModal from "@/hooks/useDialogModal";
import { Trans } from "react-i18next";

const { t } = i18n;

class RevertToLastTransaction extends Component {
	state = {
		createdBy: "",
	};

	doRevert = async () => {
		const {
			listInvestors,
			deleteLastTransaction,
			cancelTemporaryTransactions,
			confirm,
			transaction,
		} = this.props;

		let isRevertConfirmed = false;

		if (transaction.get("type") === "GENESIS") {
			isRevertConfirmed = await confirm(
				t("share_register.remove_share_register"),
				<span>
					<Trans i18nKey="reset_shares_to_genesis_message" />
				</span>,
				{
					variant: "destructive",
					submitText: (
						<>
							<Trash2 size={16} />
							{t("share_register.remove_share_register")}
						</>
					),
				},
			);
		} else {
			isRevertConfirmed = await confirm(
				t("shares.transactions.revert.last.transaction"),
				<span>
					<Trans i18nKey="revert_transaction_info" />
				</span>,
				{
					variant: "warning",
					submitText: (
						<>
							<Undo2 size={16} />
							{t("shares.transactions.revert.last.transaction")}
						</>
					),
				},
			);
		}

		if (!isRevertConfirmed) return;

		cancelTemporaryTransactions();
		deleteLastTransaction();
		listInvestors();
	};

	componentDidMount = () => {
		if (this.props.transaction && this.props.transaction.get("createdBy")) {
			this.getCreatedBy(this.props.transaction.get("createdBy"));
		}
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.transaction !== this.props.transaction) {
			if (!this.props.transaction) {
				this.props.fetchMenu("main");
				this.props.history.push(
					"/" + this.props.company.urlAlias + "/shares/setup",
				);
				return;
			}
			if (
				prevProps.transaction.get("id") !== this.props.transaction.get("id")
			) {
				this.setState({ expanded: false });
			}

			this.getCreatedBy(this.props.transaction.get("createdBy"));
		}
	};

	getCreatedBy = (createBy) => {
		if (!createBy) {
			return;
		}

		req
			.get(`/users/public/users/${createBy}`)
			.then((response) => {
				this.setState({ createdBy: response.data.name });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render = () => {
		const { transaction, dialogElement } = this.props;

		if (!transaction || !transaction.get("id")) {
			return null;
		}

		const date = Moment(transaction.get("date"));
		let type = transaction.get("type");

		return (
			<div className="mb-4">
				{dialogElement}
				<Panel className="!pb-0">
					<div className="flex justify-between p-6 items-center">
						{t(type)}
						<div>{date.format("L")}</div>
						<Button variant="secondary" onClick={this.doRevert}>
							<Undo2 />
							{t("shares.transactions.revert.last.transaction")}
						</Button>
					</div>
				</Panel>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		history: history,
		transaction: state.transaction.get("transaction"),
		company: state.company.company,
		i18n: state.i18n,
	};
}

const mapActionsToProps = {
	deleteLastTransaction,
	cancelTemporaryTransactions,
	listInvestors,
	fetchMenu,
};

const RevertToLastTransactionConnected = connect(
	mapStateToProps,
	mapActionsToProps,
)(RevertToLastTransaction);

export default withResolvedProps(() => {
	const { confirm, dialogElement } = useDialogModal();

	return { confirm, dialogElement };
})(RevertToLastTransactionConnected);
