import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Map, List } from "immutable";
import Moment from "../../../modules/moment.module";
import {
	getShareTypeLabel,
	getTransactionLimitationsLabel,
} from "../../helpers/shares";
import { validatePersonNumber } from "../../../modules/validation.module";
import { fetchCertificates } from "../../../actions/share-register.actions";
import { listTransactionsForSharebook } from "../../../actions/transaction.actions";
import req from "../../../modules/request.module";
import { formatIdPretty } from "../../helpers/users";
import ShowMoreBox from "../../framework/show-more-box";
import Text from "../../../dumb-components/shared/text/text";
import { ButtonTransparentIcon } from "../../../dumb-components/shared/button";
import Tooltip from "../../../dumb-components/shared/tooltip/tooltip";
import { openModal } from "../../../actions/modals.actions";

import ScrollView from "../../../dumb-components/shared/layout/scroll-view/scroll-view";
import DropdownMenuContainer from "../../../containers/shared/dropdown-menu.container";
import DropdownIconItem from "../../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import { SHAREBOOK_FILTER_BY_TRANSACION } from "../../../constants/modals";
import FilterByCertificateModalContainer from "../../../containers/dashboard/filter-by-certificate-modal.container";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";
import { listDepos } from "../../../actions/depos.actions";
import history, { getQuery } from "../../../interfaces/history";

import {
	EVENT_TYPE_SHARES_TRANSACTION_CREATE,
	EVENT_TYPE_SHARES_TRANSACTION_DELETE,
	NUMBER_FORMAT_INTEGER,
} from "/shared/constants";
import withResolvedProps from "../../../hocs/withResolvedProps";
import { Award } from "lucide-react";
import useRestrictAccess from "@/hooks/useRestrictAccess";
import i18n from "@/i18n";
import Breadcrumbs from "@/components/breadcrumbs";

const { t } = i18n;

class ShareRegister extends Component {
	state = {
		transactionsOptions: List(),
		transactionDate: null,
		isGeneratingExcelExport: false,
	};

	componentDidMount() {
		const { transactions } = this.props;

		this.props.listTransactionsForSharebook();
		this.fetchCertificates();
		this.props.listDepos();

		if (transactions.size > 0) {
			this.setState({ transactionDate: this.getTransactionDate() });
		}
	}

	componentDidUpdate = (prevProps) => {
		const { i18n, location, transactions } = this.props;

		this.checkLiveUpdateEvents();
		if (i18n.language !== prevProps.i18n.language) {
			this.fetchCertificates();
		}

		const newQueryObj =
			location && location.search && getQuery(location.search);
		const newQuery = newQueryObj && newQueryObj.transactionId;
		const oldQueryObj =
			prevProps.location &&
			prevProps.location.search &&
			getQuery(prevProps.location.search);
		const oldQuery = oldQueryObj && oldQueryObj.transactionId;

		// Location can be/is changed from the history modal
		if (oldQuery !== newQuery) {
			this.fetchCertificates();
		}

		if (oldQuery !== newQuery || prevProps.transactions !== transactions) {
			this.fetchCertificates();
			this.setState({ transactionDate: this.getTransactionDate() });
		}
	};

	checkLiveUpdateEvents = () => {
		const { audit, fetchCertificates } = this.props;
		const transactionCreate = audit.get(EVENT_TYPE_SHARES_TRANSACTION_CREATE);
		const transactionDelete = audit.get(EVENT_TYPE_SHARES_TRANSACTION_DELETE);

		if (
			(transactionCreate && transactionCreate.get("refresh") === true) ||
			(transactionDelete && transactionDelete.get("refresh") === true)
		) {
			fetchCertificates();
		}
	};

	fetchCertificates = () => {
		const { fetchCertificates } = this.props;
		const query = getQuery();
		if (query && query.transactionId) {
			fetchCertificates(query.transactionId);
		} else {
			fetchCertificates();
		}
	};

	getLatestTransaction = () => {
		const { transactions } = this.props;

		if (transactions.size > 0) {
			return transactions.first();
		}
	};

	getTransactionDate = () => {
		const { transactions } = this.props;
		const transactionId =
			history.location.search && history.location.search.split("=")[1];

		if (transactionId) {
			const getTransaction = transactions.find(
				(transaction) => transaction.get("id") === transactionId,
			);
			const transactionDate = getTransaction.get("date");
			const date = Moment(transactionDate).format("L");

			return date;
		} else {
			const latestTransactionDate = this.getLatestTransaction().get("date");
			const date = Moment(latestTransactionDate).format("L");

			return date;
		}
	};

	selectTransaction = (id) => {
		const {
			location: { pathname },
			history,
		} = this.props;

		if (id) {
			history.push({
				pathname,
				search: `?transactionId=${id}`,
			});
		} else {
			history.push(pathname);
		}
	};

	formatLabel = (transaction) => {
		let date = transaction.get("date");
		date = `${Moment(date).format("L")}`;
		return (
			<>
				{" "}
				<Text tid={transaction.get("type")} /> <Text color="muted">{date}</Text>{" "}
			</>
		);
	};

	createPdf = (withHistory) => {
		const { transactionDate } = this.state;
		const query = getQuery();
		const win = window.open(
			`https://${window.location.hostname}/assets/build/misc/generating-pdf.html`,
			"_blank",
		);
		req
			.get(
				`/shares/pdf/certificates?history=${withHistory}${
					query.transactionId ? `&id=${query.transactionId}` : ""
				}&transactionDate=${transactionDate}`,
			)
			.then(() => {
				win.location = `/api/pdf/shares/share-register-${this.props.company.id}`;
			});

		this.btnPrintShareholdersRef && this.btnPrintShareholdersRef.onToggleMenu();
	};

	printSequences = () => {
		const query = getQuery();
		const win = window.open(
			`https://${window.location.hostname}/assets/build/misc/generating-pdf.html`,
			"_blank",
		);
		req
			.get(
				`/shares/pdf/sequences${
					query.transactionId ? `?id=${query.transactionId}` : ""
				}`,
			)
			.then(() => {
				win.location = `/api/pdf/shares/sequences-${this.props.company.id}`;
			});

		this.btnPrintShareholdersRef && this.btnPrintShareholdersRef.onToggleMenu();
	};

	renderDescription = (sequence, relatedTransactionId, index) => {
		const [id] = relatedTransactionId.split("$");
		const transaction = sequence.getIn(["transactions", id]);
		const date = transaction.get("date");
		const description = transaction.get("description");
		return (
			<div className="list__item list__item--pad-lft-8" key={index}>
				<div className="list__item__cert-left-item">
					{Moment(date).format("L")}
				</div>
				<div className="f1-box">{description}</div>
			</div>
		);
	};

	renderPledge = (pledge, index) => {
		return (
			<div className="list__item__pledge__row" key={index}>
				<div className="list__item__pledge__item">
					{Moment(pledge.get("date")).format("L")}
				</div>
				<div className="list__item__pledge__item">
					{pledge.get("reference")}
				</div>
			</div>
		);
	};

	renderPledges = () => {
		return (
			<div className="list__item__pledge">
				<Text tid="Yes" />
			</div>
		);
	};

	renderInvestorAddress(investorInformation) {
		const investorDetails = [];

		if (investorInformation.get("address")) {
			investorDetails.push(investorInformation.get("address"));
		}
		if (investorInformation.get("zip")) {
			investorDetails.push(investorInformation.get("zip"));
		}
		if (investorInformation.get("city")) {
			investorDetails.push(investorInformation.get("city"));
		}
		if (investorInformation.get("country")) {
			investorDetails.push(investorInformation.get("country"));
		}

		return investorDetails.join(", ");
	}

	renderCertificate = (sequence, index) => {
		const { transactionDate } = this.state;
		const investorInformation = sequence.get("investorInformation");

		let unformattedShareType = sequence.get("type");
		if (unformattedShareType.includes("$null")) {
			unformattedShareType = unformattedShareType.replace("$null", "$");
		}
		const shareType = getShareTypeLabel(unformattedShareType);

		const transactionLimitations =
			sequence.get("transactionLimitations", List()) || List();
		const pledges = sequence.get("pledges", List()) || List();
		const totalNumberOfShares =
			sequence.get("sequenceTo") - sequence.get("sequenceFrom") + 1;

		if (!investorInformation) {
			return null;
		}

		const deposIdToNameMap = this.props.deposIdToNameMap || Map();
		const id = investorInformation.get("id");
		const investorTypeOfOwner = sequence.get("investorTypeOfOwner");

		let tid = "foreign_owner_free_text";
		if (investorTypeOfOwner === "private" && validatePersonNumber(id)) {
			tid = "generic.ssn";
		} else if (investorTypeOfOwner === "company") {
			tid = "organisation_nr";
		} else if (investorTypeOfOwner === "capitalInsurance") {
			tid = "organisation_nr";
		}

		let depoName = "";

		if (investorInformation.get("depositoryInstitute")) {
			depoName = deposIdToNameMap.get(
				investorInformation.get("depositoryInstitute"),
			);
		}

		return (
			<div className="i-panel i-panel--white" key={index}>
				<div className="i-panel__heading i-panel__heading--big rounded-t-lg overflow-hidden">
					<div className="i-panel__title-wrapper i-panel__title-wrapper--row-space-between">
						<h2 className="i-panel__title">
							<div className="flex items-center gap-4">
								<span className="text-lg inline-flex gap-2 items-center opacity-70">
									<Award size={18} />
									<FormattedMessage id="sequence" />
								</span>
								{`${localeFormatNumber(
									sequence.get("sequenceFrom"),
									NUMBER_FORMAT_INTEGER,
								)} - ${localeFormatNumber(
									sequence.get("sequenceTo"),
									NUMBER_FORMAT_INTEGER,
								)}`}
							</div>
						</h2>
						<h2 className="i-panel__title">
							<span className="i-panel__title__text-muted">
								<FormattedMessage id="shares.register.title.certificate" />
							</span>{" "}
							{localeFormatNumber(index + 1, NUMBER_FORMAT_INTEGER)}
						</h2>
					</div>
				</div>
				<div className="i-panel__body i-panel__body--pad-hor">
					<div className="list__list-header">
						<div className="list__list-header__col--2">
							<FormattedMessage id="shares.share_register.transactions_until" />
						</div>
						<div className="list__list-header__col--2">
							<FormattedMessage id="type_of_share" />
						</div>
						<div className="list__list-header__col--2">
							<FormattedMessage id="owner" />
						</div>
						<div className="list__list-header__col--2">
							<FormattedMessage id={tid} />
						</div>
						<div className="list__list-header__col">
							<FormattedMessage id="generic.address" />
						</div>
					</div>
					<div className="list list--striped list--table list--mar-btm-x2">
						<div className="list__item">
							<div className="list__item__col--2">{transactionDate}</div>
							<div className="list__item__col--2">{shareType}</div>
							<div className="list__item__col--2">
								{investorInformation.get("name")}
							</div>
							<div className="list__item__col--2">{formatIdPretty(id)}</div>
							<div className="list__item__col">
								{this.renderInvestorAddress(investorInformation)}
							</div>
						</div>
					</div>

					<div className="list list--table list--mar-btm-x2">
						{investorTypeOfOwner === "capitalInsurance" && (
							<div className="list__item">
								<div className="list__item--pad-lft-8 list__item--horizontal-flex">
									<div className="list__item__cert-left-item">
										<strong>
											<FormattedMessage id="shares.capital_insurance" />
										</strong>
									</div>
									{depoName}&nbsp;
									<FormattedMessage id="shares.capital_insurance.for" />
									&nbsp;{investorInformation.get("name")} -{" "}
									{investorInformation.get("insuranceNr")}
								</div>
							</div>
						)}

						{/* [START] Total Number of Shares */}
						<div className="list__item">
							<div className="list__item--pad-lft-8 list__item--horizontal-flex">
								<div className="list__item__cert-left-item">
									<strong>
										<FormattedMessage id="shares.total_number_of_shares" />
									</strong>
								</div>
								{totalNumberOfShares}
							</div>
						</div>
						{/* [END] Total Number of Shares */}

						<div className="list__item">
							<div className="list__item--pad-lft-8 list__item--horizontal-flex">
								<div className="list__item__cert-left-item">
									<strong>
										<FormattedMessage id="shares.transaction_restrictions" />
									</strong>
								</div>
								{transactionLimitations.size > 0 ? (
									getTransactionLimitationsLabel(
										sequence.get("transactionLimitations"),
									)
								) : (
									<FormattedMessage id="share_register.transaction_limitations.none" />
								)}
							</div>
						</div>
						{sequence.get("letterSent") ? (
							<div className="list__item">
								<div className="list__item--pad-lft-8 list__item--horizontal-flex">
									<div className="list__item__cert-left-item">
										<strong>
											<FormattedMessage id="shares.certificates.share_letter_sent" />
										</strong>
									</div>
									{Moment(sequence.get("letterSentAt")).format("L")}
								</div>
							</div>
						) : (
							<div className="list__item">
								<div className="list__item--pad-lft-8 list__item--horizontal-flex">
									<div className="list__item__cert-left-item">
										<strong>
											<FormattedMessage id="shares.certificates.share_letter_not_sent" />
										</strong>
									</div>
								</div>
							</div>
						)}
						{pledges.size > 0 && (
							<div className="list__item">
								<div className="list__item--pad-lft-8 list__item--horizontal-flex">
									<div className="list__item__cert-left-item">
										<strong>
											<FormattedMessage id="shares.certificate.share_pledge" />
										</strong>
									</div>
									{this.renderPledges(pledges)}
								</div>
							</div>
						)}
					</div>

					<div className="list__list-header">
						<div className="list__list-header__col">
							<FormattedMessage id="share_register.history" />
						</div>
					</div>
					<div className="list list--striped list--table">
						<ShowMoreBox>
							{sequence
								.get("relatedTransactions")
								.map(this.renderDescription.bind(this, sequence))}
						</ShowMoreBox>
					</div>
				</div>
			</div>
		);
	};

	openFilterModal = () => {
		const { openModal } = this.props;
		openModal(SHAREBOOK_FILTER_BY_TRANSACION);
	};

	onClickSequencesExcelDownload = () => {
		const { isGeneratingExcelExport } = this.state;

		if (isGeneratingExcelExport) return;

		this.setState({ isGeneratingExcelExport: true });
		req
			.post("/shares/excel/sequences")
			.then((response) => {
				this.setState({ isGeneratingExcelExport: false });

				window.open(
					`https://view.officeapps.live.com/op/view.aspx?src=${window.encodeURIComponent(
						response.data.downloadUrl,
					)}`,
					"_blank",
				);
			})
			.catch((e) => {
				console.log(e);
				this.setState({ isGeneratingExcelExport: false });
			});
	};

	render = () => {
		const {
			certificates,
			transactions,
			companyAccountIsFree,
			restrictDialogElement,
			restrict,
		} = this.props;

		const { isGeneratingExcelExport } = this.state;
		const query = getQuery();
		const selectedTransactionId = query && query.transactionId;
		const selectedTransaction = selectedTransactionId
			? transactions.find((obj) => obj.get("id") === selectedTransactionId)
			: null;
		const hasAppliedTransaction = selectedTransactionId ? true : false;
		const lastTransactionId =
			transactions && transactions.size > 0 && transactions.first().get("id");

		if (!certificates) {
			return null;
		}

		const sequences = certificates;

		if (!sequences) {
			return null;
		}

		return (
			<div className="flex flex-col h-full p-md">
				<Breadcrumbs
					items={[{ label: t("shares.sharebook.breadcrumbs.title") }]}
				/>
				<div className="i-content__body">
					<div className="i-content__container">
						<div className="i-content__tabs i-content__tabs--left pl-0">
							<div className="tabs-text">
								<h4>
									<FormattedMessage id="certificates" /> -{" "}
									<span className="text--muted">
										<FormattedMessage id="showing" />
									</span>{" "}
									{sequences.size}
								</h4>
							</div>
							<div className="tabs-button-container">
								{selectedTransaction && (
									<div style={{ display: "flex", alignItems: "center" }}>
										<Text tid={selectedTransaction.get("type")} singleLine />
									</div>
								)}

								<Tooltip tid="shares.sharebook.toolbar.historic.tooltip">
									<ButtonTransparentIcon
										icon="faHistory"
										size="sml"
										onClick={this.openFilterModal}
										notificationBadge={hasAppliedTransaction}
									/>
								</Tooltip>

								<DropdownMenuContainer
									btnIcon="faPrint"
									btnMode="transparent-icon"
									halignMenu="right"
									transparentIconButtonSize="sml"
									ref={(ref) => (this.btnPrintShareholdersRef = ref)}
									noMaxWidth={true}
								>
									<DropdownIconItem
										tid="investments.print_share_register"
										icon="faFile"
										onClick={this.createPdf.bind(this, false)}
									/>{" "}
									<DropdownIconItem
										tid="investments.print_share_register_with_history"
										icon="faCopy"
										onClick={this.createPdf.bind(this, true)}
									/>
								</DropdownMenuContainer>
								<DropdownMenuContainer
									btnIcon="faFileSpreadsheet"
									halignMenu="right"
									btnMode="transparent-icon"
									transparentIconButtonSize="sml"
									noMaxWidth={true}
									ref={(ref) => (this.downloadBtnRef = ref)}
								>
									{restrict(
										<DropdownIconItem
											icon={
												isGeneratingExcelExport
													? "faSpinner"
													: "faFileSpreadsheet"
											}
											iconSpin={isGeneratingExcelExport}
											onClick={this.onClickSequencesExcelDownload}
											disabled={companyAccountIsFree}
										>
											{t("investors.download_sequences_excel")}
										</DropdownIconItem>,
										{
											requiredFeature: "share-register-export-excel",
										},
									)}
								</DropdownMenuContainer>
							</div>
						</div>

						<FilterByCertificateModalContainer
							selectedTransactionId={selectedTransactionId}
							onChangeTransaction={this.selectTransaction}
							lastTransactionId={lastTransactionId}
						/>

						<ScrollView showOnHover={true} autoHide={true} noLeftMargin={true}>
							{sequences.map(this.renderCertificate)}
						</ScrollView>
					</div>
				</div>
				{restrictDialogElement}
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		history: history,
		certificates: state.shareRegister.get("certificates"),
		transactions: state.transaction.get("transactions"),
		company: state.company.company,
		i18n: state.i18n,
		audit: state.audit.get("transactions"),
		deposIdToNameMap: state.depos.get("deposIdToNameMap"),
	};
}

const mapActionsToProps = {
	listTransactionsForSharebook,
	fetchCertificates,
	openModal,
	listDepos,
};

const ConnectedShareRegister = connect(
	mapStateToProps,
	mapActionsToProps,
)(ShareRegister);

export default withResolvedProps(() => {
	const { restrict, restrictDialogElement } = useRestrictAccess();
	return { restrict, restrictDialogElement };
})(ConnectedShareRegister);
