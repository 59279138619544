import React from "react";
import { Tooltip as Base, TooltipContent, TooltipTrigger } from "./ui/tooltip";

const Tooltip = ({ children, value }) =>
	value?.length > 0 ? (
		<Base delayDuration={300}>
			<TooltipTrigger asChild>{children}</TooltipTrigger>
			<TooltipContent>{value}</TooltipContent>
		</Base>
	) : (
		children
	);

export default Tooltip;
