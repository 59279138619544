import React, { Component } from "react";
import { connect } from "react-redux";
import {
	FormattedMessage,
	FormattedHTMLMessage,
	injectIntl,
	intlShape,
} from "react-intl";
import { fromJS, Map } from "immutable";
import debounce from "lodash/debounce";
import ScrollView from "../../../../dumb-components/shared/layout/scroll-view/scroll-view";
import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	deleteLastTransaction,
	resetAdjustmentTransaction,
	cancelTemporaryTransaction,
} from "../../../../actions/transaction.actions";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../../../actions/notify.actions";
import DatePicker from "../../../../dumb-components/shared/datepicker/datepicker";
import Panel from "../../../../dumb-components/panel";
import Field from "../../../../dumb-components/fields/field";
import Select from "../../../../dumb-components/fields/select";
import NumericInput from "../../../../dumb-components/fields/numeric-input";
import {
	validateTransactionDate,
	isRequired,
	validateisRequiredImmutable,
	validateNumOfSharesToDistributeGtZero,
	validateDistributedNumOfSharesNotGtLimit,
	validateBeforeAndAfterGtZero,
	validateShareTypeNotNull,
} from "../../../../modules/validation.module";
import { List } from "immutable";
import ShareTypes from "./share-types";
import EmissionLeveling from "./emission-leveling";
import immutableForm from "../../../framework/immutable-form";
import FormButtonArea from "./formButtonArea";

const transactionType = "BONUS-ISSUE";

const initialTransaction = fromJS({
	type: transactionType,
	handlerData: {},
});

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS());
}, 1000);

class BonusIssueForm extends Component {
	static propTypes = {
		intl: intlShape.isRequired,
	};

	componentDidMount = () => {
		const { tmpTransaction } = this.props;
		this.props.fetchTemporaryTransaction(transactionType);
		this.setValidators(tmpTransaction);
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.tmpTransaction !== prevProps.tmpTransaction) {
			this.setValidators(this.props.tmpTransaction);
		}
	};

	scrollToEmissionLeveling = () => {
		setTimeout(() => {
			const emissionLevelingWrapper = document.getElementById(
				"emission-leveling-wrapper",
			);

			if (!emissionLevelingWrapper) {
				return;
			}

			this.scrollbar.scrollTop(emissionLevelingWrapper.offsetTop);
		}, 500);
	};

	setValidators = (tmpTransaction) => {
		const { disableValidationOfField, enableValidationOfField } = this.props;

		if (tmpTransaction.getIn(["handlerData", "type"]) === "quota") {
			disableValidationOfField("handlerData.shareTypes");
			disableValidationOfField("handlerData.investments");
			enableValidationOfField("handlerData.increaseShareCapitalBy");
			return;
		}

		if (tmpTransaction.getIn(["handlerData", "type"]) === "shares") {
			enableValidationOfField("handlerData.shareTypes");
			enableValidationOfField("handlerData.investments");
			disableValidationOfField("handlerData.increaseShareCapitalBy");
			return;
		}

		// Default validators
		disableValidationOfField("handlerData.shareTypes");
		disableValidationOfField("handlerData.investments");
		disableValidationOfField("handlerData.increaseShareCapitalBy");
	};

	onChange = (field, val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } =
			this.props;
		const newTransaction = tmpTransaction.setIn(["handlerData", field], val);
		this.props.resetErrors("handlerData." + field);
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeDate = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } =
			this.props;
		const newTransaction = tmpTransaction.set("date", val);
		this.props.resetErrors("date");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onChangeShareType = (val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
		} = this.props;
		let newTransaction = tmpTransaction.removeIn(["handlerData", "shareTypes"]);
		newTransaction = newTransaction.removeIn([
			"handlerData",
			"increaseShareCapitalBy",
		]);
		newTransaction = newTransaction.setIn(["handlerData", "type"], val);
		resetErrors("handlerData.type");
		updateTransaction(transactionType, newTransaction);
		de(saveTemporaryTransaction, transactionType, newTransaction);
	};

	onSubmit = () => {
		const { tmpTransaction, createTransaction, resetAdjustmentTransaction } =
			this.props;

		if (this.props.validate(tmpTransaction)) {
			createTransaction(
				tmpTransaction.toJS(),
				() => {
					resetAdjustmentTransaction();
				},
				this.scrollToEmissionLeveling,
			);
		}
	};

	cancelTransaction = () => {
		this.props.cancelTemporaryTransaction(transactionType);
		this.props.resetAdjustmentTransaction();
	};

	acceptAdjustment = () => {
		const {
			tmpTransaction,
			validate,
			createTransaction,
			resetAdjustmentTransaction,
		} = this.props;

		const newTransaction = tmpTransaction.setIn(
			["handlerData", "accepted"],
			true,
		);

		if (validate(newTransaction)) {
			createTransaction(newTransaction.toJS(), () => {
				resetAdjustmentTransaction();
			});
		}
	};

	formatNumerInput = (num) => {
		const { formatNumber } = this.props.intl;

		if (isNaN(num)) {
			return num;
		}

		return formatNumber(num);
	};

	renderShareCapitalInput = () => {
		const { tmpTransaction, errors } = this.props;

		return (
			<Field name="handlerData.increaseShareCapitalBy" errors={errors}>
				<div className="list list--striped list--table">
					<div className="list__item">
						<div className="list__item__body">
							<span className="list__item__text">
								<FormattedMessage id="shares.transactions.bonus_issue.form.increase_share_capital_by" />
							</span>
						</div>
						<span className="list__item__text-area list__item__text-area--x2">
							<span className="list__item__input list__item__input--pad-right">
								<NumericInput
									type="text"
									thousandSeparator=" "
									decimalSeparator=","
									allowDecimals
									className="form-control text--align-right"
									value={tmpTransaction.getIn([
										"handlerData",
										"increaseShareCapitalBy",
									])}
									onChange={(val) => {
										this.onChange("increaseShareCapitalBy", val);
									}}
								/>
							</span>
						</span>
					</div>
				</div>
			</Field>
		);
	};

	render = () => {
		const { transaction, tmpTransaction, i18n, errors } = this.props;

		if (!tmpTransaction) {
			return null;
		}

		const typesOptions = [
			{
				value: "shares",
				label:
					i18n.messages[
						"shares.transactions.bonus_issue.form.change_number_of_shares"
					],
			},
			{
				value: "quota",
				label:
					i18n.messages[
						"shares.transactions.bonus_issue.form.change_quota_value"
					],
			},
		];

		const bonusIssueType = tmpTransaction.getIn(["handlerData", "type"]);
		const investments = tmpTransaction.getIn(
			["handlerData", "investments"],
			Map(),
		);

		const handleSubmit =
			investments.size > 0 ? this.acceptAdjustment : this.onSubmit;

		return (
			<div className="i-content__container">
				<ScrollView
					scrollbarRef={(r) => (this.scrollbar = r)}
					showOnHover={true}
					autoHide={true}
				>
					<Panel tid="bonus_issue" className="rounded-b-none mb-0 border-b-0">
						<Field
							name="handlerData.type"
							tid="shares.transactions.bonus_issue.form.type"
							className="form-group--mar-btm-10 col-md-7"
							errors={errors}
						>
							<Select
								value={bonusIssueType}
								options={typesOptions}
								placeholder={i18n.messages["select_placeholder"]}
								simpleValue
								onSelect={(val) => {
									this.onChangeShareType(val);
								}}
							/>
						</Field>

						<Field
							name="date"
							tid="generic.date"
							className="form-group--mar-btm-10 col-md-5"
							errors={errors}
						>
							<DatePicker
								value={tmpTransaction.get("date")}
								hasError={errors ? errors.get("date", List()).size > 0 : false}
								calendarPlacement="bottom-end"
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						{bonusIssueType === "shares" && (
							<Field name="handlerData.shareTypes" errors={errors}>
								<ShareTypes
									latestTransaction={transaction}
									appendIsNewOnSubmit
									shareTypes={transaction.getIn(["shareData", "types"])}
									value={tmpTransaction.getIn(["handlerData", "shareTypes"])}
									onChange={(shareTypes) => {
										this.onChange("shareTypes", shareTypes);
									}}
									showPriceField={false}
									readonly={investments.size > 0}
								/>
							</Field>
						)}

						{bonusIssueType === "quota" && this.renderShareCapitalInput()}

						<div className="form-group--mar-btm-0 col-md-12">
							{bonusIssueType === "shares" && (
								<FormattedHTMLMessage id="shares.transactions.bonus_issue.form.shares.information" />
							)}
							{bonusIssueType === "quota" && (
								<FormattedHTMLMessage id="shares.transactions.bonus_issue.form.quota.information" />
							)}
						</div>
						{investments.size > 0 && (
							<div
								id="emission-leveling-wrapper"
								className="float-start mt-[24px]"
							>
								<div>
									<div className="attendees-alert-wrapper px-[15px]">
										<div className="alert alert-warning alert--icon">
											<div className="icon-container">
												<i className="fa fa-exclamation-triangle"></i>
											</div>
											<div className="alert-text">
												<FormattedHTMLMessage id="shares.transactions.bonus_issue.alert" />
											</div>
										</div>
									</div>

									<div className="form-group col-md-12">
										<FormattedHTMLMessage id="shares.transactions.bonus_issue.adjustment_information" />
									</div>
								</div>

								<EmissionLeveling
									name="handlerData.investments"
									errors={errors}
									latestTransaction={transaction}
									shareDiff={tmpTransaction.getIn(
										["handlerData", "investments"],
										Map(),
									)}
									increaseDiff
									allowAdjustment
									onChange={(field, val) => {
										this.onChange("investments", val);
									}}
								/>
							</div>
						)}
					</Panel>

					<FormButtonArea
						onCancelClick={this.cancelTransaction}
						onSubmitClick={handleSubmit}
					/>
				</ScrollView>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get("transaction"),
		tmpTransaction: state.transaction.getIn(
			["tmpTransaction", transactionType],
			initialTransaction,
		),
		investors: state.investors.get("list"),
		i18n: state.i18n,
	};
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	addErrorNotification,
	addInfoNotification,
	deleteLastTransaction,
	resetAdjustmentTransaction,
	cancelTemporaryTransaction,
};

const validators = fromJS({
	date: {
		tid: "generic.date",
		rules: [
			{
				func: isRequired,
				message: "validation.is_required",
			},
			{
				func: validateTransactionDate,
				message:
					"validation.current_transaction_date_must_be_later_then_last_transaction",
			},
		],
	},
	"handlerData.type": {
		tid: "shares.transactions.bonus_issue.form.type",
		rules: [{ func: isRequired, message: "validation.is_required" }],
	},
	"handlerData.shareTypes": {
		tid: "shares.transactions.bonus_issue.class_of_shares",
		rules: [
			{ func: validateisRequiredImmutable, message: "validation.is_required" },
			{
				func: validateNumOfSharesToDistributeGtZero,
				message: "validation.num_of_shares_to_distribute_gt_0",
			},
			{
				func: validateShareTypeNotNull,
				message: "validation.share_type_not_specified",
			},
		],
	},
	"handlerData.investments": {
		rules: [
			{
				func: validateDistributedNumOfSharesNotGtLimit,
				message: "validation.distributed_num_of_shares_not_gt_limit",
			},
		],
	},
	"handlerData.increaseShareCapitalBy": {
		tid: "shares.transactions.bonus_issue.form.increase_share_capital_by",
		rules: [
			{ func: isRequired, message: "validation.is_required" },
			{
				func: validateBeforeAndAfterGtZero,
				message: "validation.distributed_num_of_shares_not_gt_limit",
			},
		],
	},
});

const BonusIssueFormImmutableForm = immutableForm(
	BonusIssueForm,
	"bonusIssue",
	validators,
);
const BonusIssueFormInjectIntl = injectIntl(BonusIssueFormImmutableForm);
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(BonusIssueFormInjectIntl);
