import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { APPEAL_TO_COMPANY_MODAL } from "../../constants/modals";
import { closeModal } from "../../actions/modals.actions";
import { useEffect, useState } from "react";
import MUIDialog from "../../mui-components/dialog/mui-dialog";
import { Box, TextField } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../../modules/request.module.v2";
import CenteredLoading from "../../dumb-components/shared/modal/centered-loading";
import {
	addErrorNotification,
	addInfoNotification,
} from "../../actions/notify.actions";

const PLAN_UPGRADE_APPEAL_FEATURE = "CAPTABLE_ACCESS_INVESTOR";

const AppealToCompanyModal = ({ companyId, companyName }) => {
	const [mailContent, setMailContent] = useState("");
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isOpen = useSelector(
		(state) =>
			state.modals.getIn(["activeModal", "name"]) === APPEAL_TO_COMPANY_MODAL,
	);
	const userName = useSelector((state) =>
		state.user.getIn(["userObj", "name"]),
	);
	const userId = useSelector((state) => state.user.getIn(["userObj", "id"]));

	const { isLoading, data: existingAppeals } = useQuery({
		queryKey: ["companyPlanUpgradeAppeal", { companyId, userId }],
		queryFn: ({ queryKey }) => {
			const [_key, { companyId, userId }] = queryKey;
			return apiClient
				.get(`companies/${companyId}/plan-upgrade-appeals/${userId}`, {
					params: { companyId },
				})
				.then((res) => res.data);
		},
	});

	const { mutate: sendCompanyPlanUpgradeAppeal } = useMutation({
		mutationKey: [
			"sendCompanyPlanUpgradeAppeal",
			{ companyId, feature: PLAN_UPGRADE_APPEAL_FEATURE },
		],
		mutationFn: ({ appealBody, feature }) =>
			apiClient.post(`/companies/${companyId}/plan-upgrade-appeals`, {
				appealBody,
				feature,
			}),
		onSuccess: () => {
			dispatch(
				addInfoNotification({ tid: "companies.info.appeal_email_sent" }),
			);
			onClose();
		},
		onError: () => {
			dispatch(
				addErrorNotification({ tid: "companies.error.fail_to_send_mail" }),
			);
		},
	});

	useEffect(() => {
		setMailContent(
			t("investments.appeal_to_company.mail_content", {
				companyName,
				userName,
			}),
		);
	}, [companyName, userName]);

	const onClose = () => {
		dispatch(closeModal());
	};

	const onChange = (e) => {
		setMailContent(e.target.value);
	};

	if (!isOpen) {
		return null;
	}

	const existingAppeal = existingAppeals?.find(
		(appeal) => appeal.feature === PLAN_UPGRADE_APPEAL_FEATURE,
	);

	return (
		<MUIDialog
			isOpen={true}
			onClose={onClose}
			title={t("investments.appeal_to_company.title", { companyName })}
			confirmButton={true}
			confirmButtonTid={
				existingAppeal
					? "investments.appeal_to_company.already_sent_appeal"
					: "investments.appeal_to_company.send_mail"
			}
			onConfirm={() =>
				sendCompanyPlanUpgradeAppeal({
					appealBody: mailContent,
					feature: PLAN_UPGRADE_APPEAL_FEATURE,
				})
			}
			closeButtonTid={"investments.appel_to_company.close"}
			maxWidth="lg"
			fullWidth
			height="100%"
			disabledConfirm={!!existingAppeal}
		>
			{isLoading ? (
				<CenteredLoading />
			) : (
				<>
					<p>{t("investments.appeal_to_company.info1", { companyName })}</p>
					<p>{t("investments.appeal_to_company.info2")}</p>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						style={{ marginBlock: "32px" }}
					>
						<TextField
							id="investments.appeal_to_company.mail_content"
							label={t("investments.appeal_to_company.mail_content.label")}
							multiline
							rows={15}
							value={existingAppeal?.appealBody ?? mailContent}
							onChange={onChange}
							fullWidth
							disabled={!!existingAppeal}
						/>
					</Box>
					<p>{t("investments.appeal_to_company.info3")}</p>
					<p>{t("investments.appeal_to_company.info4")}</p>
				</>
			)}
		</MUIDialog>
	);
};

export default AppealToCompanyModal;
