import { useQuery } from "@tanstack/react-query";
import apiClient from "../modules/request.module.v2";
import useCompanyIdFromUrl from "./useCompanyIdFromUrl";

const codeIsValid = (code) => {
	return code?.trim?.()?.length === 8;
};

export default (code) => {
	const companyId = useCompanyIdFromUrl();

	return useQuery({
		enabled: codeIsValid(code),
		queryKey: ["subscriptionCoupon", code],
		queryFn: () => {
			return apiClient.get(`subscriptions/coupon/${code}`, {
				params: { companyId },
			});
		},
		retry: false,
	});
};
