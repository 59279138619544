import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, string, number, oneOf } from "prop-types";
import immutablePropTypes from "react-immutable-proptypes";
import { Map, List } from "immutable";

import {
	remindAboutESign,
	clearDocumentsToMerge,
} from "../../actions/documents.actions";
import { fetchSimpleUsers } from "../../actions/usersCache.actions";
import { fetchAndCacheInvestors } from "../../actions/investors.actions";

import DocumentHelper from "../../components/helpers/documents.helper";
import DocumentEsignPanel from "../../dumb-components/documents/document-esign-panel/document-esign-panel";
import DocumentRequestSignButton from "./toolbar-items/document-request-sign-button";
import MainProcessControlsContainer from "./esign/main-process-controls.container";
import DocumentEsignUsersModal from "./document-esign-users-modal.container";
import DocumentRequestEsignContainer from "./document-request-esign.container";
import EsignReacreateDocumentContainer from "./esign/esign-recreate-document.container";
import SignedDocumentFilePreviewContainer from "./esign/signed-document-file-preview.container";

import { OBJ_TYPE_MEETING, OBJ_TYPE_DOCUMENT } from "/shared/constants";
import withResolvedProps from "../../hocs/withResolvedProps";
import useDialogModal from "../../hooks/useDialogModal";

import i18n from "../../i18n";
import { Trans } from "react-i18next";
import { PenLine, Send, X } from "lucide-react";

const { t } = i18n;

class EsignSignatoriesPanelContainer extends Component {
	state = {
		eSigneesToRemove: List(),
		eSigneesToAdd: List(),
		eSigningIsOngoing: false,
	};

	static propTypes = {
		onChange: func,
		selectableUsersFromExternalSource: immutablePropTypes.map,
		forceReadOnly: bool,
		renderShareWithComponent: func,
		attachmentsList: immutablePropTypes.list,
		willGenerateFile: bool,
		typeOfFileToGenerate: string,
		fileObjOwnerId: string,
		onGenereteFileInit: func,
		onGenereteFileComplete: func,
		skipFilesView: bool,
		objType: oneOf([OBJ_TYPE_DOCUMENT, OBJ_TYPE_MEETING]).isRequired,
		marginLeft: number,
		marginTop: number,
		marginRight: number,
		marginBottom: number,
		onRequestSigning: func,
		onSendSignRequest: func,
		onDeleteSignedDocument: func,
	};

	mainControlsRef = null;

	componentDidMount = () => {
		this.loadUsers();
		this.loadInvestors();
		this.setEsigningIsOngoing();
	};

	componentDidUpdate = (prevProps) => {
		const { eSignees, investors, usersCache, documentId, eSigning } =
			this.props;

		if (eSignees !== prevProps.eSignees) {
			this.loadUsers();
			this.loadInvestors();
			this.setEsigningIsOngoing();
		}

		if (
			prevProps.investors !== investors ||
			prevProps.usersCache !== usersCache
		) {
			this.forceUpdate();
		}

		if (
			documentId !== prevProps.documentId ||
			eSigning !== prevProps.eSigning
		) {
			this.setEsigningIsOngoing();
		}
	};

	loadUsers = () => {
		const { eSignees, fetchSimpleUsers } = this.props;
		const eSigneesIds = eSignees.map((signee) => signee.get("userId"));
		fetchSimpleUsers(eSigneesIds.toList());
	};

	loadInvestors = () => {
		const { eSignees, fetchAndCacheInvestors } = this.props;
		const investmentIds = eSignees
			.filter((eSignee) => !eSignee.get("isGuest"))
			.map((eSignee) => eSignee.get("userId"));
		fetchAndCacheInvestors(investmentIds);
	};

	setEsigningIsOngoing = () => {
		const { eSigning, eSignees } = this.props;
		const fakeDocumentMap = Map({ eSigning, eSignees });
		const eSigningIsOngoing =
			DocumentHelper.documentPendingEsigning(fakeDocumentMap);

		this.setState({ eSigningIsOngoing });
	};

	doDeleteSignee = (signeeId) => {
		const { onChange } = this.props;
		const { eSigningIsOngoing } = this.state;
		let { eSignees } = this.props;

		if (eSigningIsOngoing) {
			this.setState((prevState) => {
				const newState = prevState;

				if (prevState.eSigneesToAdd.has(signeeId)) {
					newState.eSigneesToAdd = prevState.eSigneesToAdd.delete(signeeId);
				} else {
					eSignees = eSignees.delete(signeeId);
					onChange("eSignees", eSignees);
				}

				return newState;
			});
			return;
		}

		eSignees = eSignees.delete(signeeId);
		onChange("eSignees", eSignees);
	};

	onDeleteSignee = async (signeeId) => {
		const { eSignees, confirm } = this.props;

		const hasSigned = eSignees.getIn([signeeId, "status"]) === "SIGNED";

		const numOfeSigneesThatHaveSigned = eSignees.filter(
			(eSignee) => eSignee.get("status") === "SIGNED",
		).size;

		const numOfSigneesNotSigned = eSignees.size - numOfeSigneesThatHaveSigned;

		const warnAboutSealing =
			numOfSigneesNotSigned === 1 &&
			numOfeSigneesThatHaveSigned > 0 &&
			!hasSigned;

		if (
			hasSigned &&
			!(await confirm(
				t("document.esign.signatories.panel.delete_signatory"),
				<div>
					<Trans
						i18nKey={"document.esign.signatories.delete.modal.confirm.question"}
					/>
				</div>,
				{
					variant: "warning",
					submitText: t("document.esign.signatories.panel.delete_signatory"),
				},
			))
		) {
			return;
		}

		if (
			warnAboutSealing &&
			!(await confirm(
				t(
					"document.esign.signatories.delete.modal.confirm.question.document_will_be_signed.title",
				),
				<div>
					<Trans
						i18nKey={
							"document.esign.signatories.delete.modal.confirm.question.document_will_be_signed.body"
						}
					/>
				</div>,
				{
					variant: "destructive",
					submitText: t(
						"document.esign.signatories.delete.modal.confirm.action.seal_document",
					),
				},
			))
		) {
			return;
		}

		this.doDeleteSignee(signeeId);
	};

	onRemindSignee = (signeeId) => {
		const { remindAboutESign, documentId } = this.props;

		remindAboutESign(documentId, signeeId);
	};

	exploreOnTangle = () => {
		const { tangleUrl } = this.props;

		if (tangleUrl) {
			window.open(tangleUrl, "_blank");
		}
	};

	getDocumentIsSealed = () => {
		const { documentAllHaveSigned, documentIsSigned, finalizationFalied } =
			this.props;
		return documentAllHaveSigned && documentIsSigned && !finalizationFalied;
	};

	goToSigningView = () => {
		const { userId, companyId, documentId } = this.props;
		window.open(
			`/public/${companyId}/documents/${documentId}/${userId}/sign`,
			"_blank",
		);
	};

	onAddSignee = (userId) => {
		const { onChange, selectableUsersFromExternalSource } = this.props;
		let { eSignees } = this.props;
		const userFromExternalSource =
			selectableUsersFromExternalSource &&
			selectableUsersFromExternalSource.get(userId);

		const newSignatoryObject = DocumentHelper.createESignatoryObject(
			userFromExternalSource || Map({ userId }),
		);

		eSignees = eSignees.set(userId, newSignatoryObject);
		onChange("eSignees", eSignees);
	};

	onResetChanges = () => {
		this.setState(() => {
			return {
				eSigneesToAdd: List(),
				eSigneesToRemove: List(),
			};
		});
	};

	onCloseRequestSigningModal = () => {
		this.setState({
			eSigneesToAdd: List(),
			eSigneesToRemove: List(),
		});
	};

	renderSigneeDropdown = (signeeId) => {
		const { userId, readOnly, forceReadOnly, eSignees } = this.props;

		const hasSigned = eSignees.getIn([signeeId, "status"]) === "SIGNED";
		const userIsSignee =
			eSignees.getIn([signeeId, "active"]) === true &&
			eSignees.getIn([signeeId, "userId"]) === userId;
		const isNotified = eSignees.getIn([signeeId, "notified"]) === true;

		return [
			userIsSignee &&
				isNotified &&
				!hasSigned && {
					label: t("documents.document.esign.signee.sign"),
					icon: <PenLine />,
					onClick: this.goToSigningView,
				},
			!readOnly &&
				!forceReadOnly &&
				!userIsSignee && {
					label: t("document.esign.signatories.panel.remind_signatory"),
					icon: <Send />,
					onClick: () => {
						this.onRemindSignee(signeeId);
					},
				},
			!readOnly &&
				!forceReadOnly && {
					label: t("document.esign.signatories.panel.delete_signatory"),
					icon: <X />,
					onClick: () => {
						this.onDeleteSignee(signeeId);
					},
				},
		];
	};

	renderLeftControls = () => {
		const {
			readOnly,
			forceReadOnly,
			willGenerateFile,
			typeOfFileToGenerate,
			fileObjOwnerId,
			leftControlsComponent,
			onGenereteFileInit,
			onGenereteFileComplete,
			attachmentsAreBeingFetched,
			objType,
			onRequestSigning,
		} = this.props;

		if (leftControlsComponent) {
			return leftControlsComponent;
		}

		return (
			<DocumentRequestSignButton
				mode="panel"
				willGenerateFile={willGenerateFile}
				typeOfFileToGenerate={typeOfFileToGenerate}
				fileObjOwnerId={fileObjOwnerId}
				onGenereteFileInit={onGenereteFileInit}
				onGenereteFileComplete={onGenereteFileComplete}
				disabled={forceReadOnly || readOnly || attachmentsAreBeingFetched}
				env={objType}
				onRequestSigning={onRequestSigning}
			/>
		);
	};

	renderBottomInformation = () => {
		const { documentAllHaveSigned } = this.props;

		if (!documentAllHaveSigned) {
			return this.renderLeftControls();
		}

		return null;
	};

	renderEsignUsersModal = () => {
		return <DocumentEsignUsersModal onChange={this.props.onChange} />;
	};

	render = () => {
		const {
			isPdf,
			willGenerateFile,
			readOnly,
			noPanel,
			usersCache,
			investors,
			documentAllHaveSigned,
			documentIsSigned,
			eSigning,
			userId,
			finialisedAt,
			finalizationFalied,
			forceReadOnly,
			renderShareWithComponent,
			skipFilesView,
			userHasSigned,
			marginLeft,
			marginTop,
			marginRight,
			marginBottom,
			eSignees,
			objType,
			onSendSignRequest,
			onDeleteSignedDocument,
			dialogElement,
		} = this.props;
		const userIsSignee = eSignees.getIn([userId, "active"]) === true;

		//in smart meetings we don't need it to be a pdf, the pdf will be generated when we ask for signatures.
		if (!isPdf && !willGenerateFile && objType !== OBJ_TYPE_MEETING) {
			return null;
		}

		return (
			<>
				<DocumentEsignPanel
					finialisedAt={finialisedAt}
					eSigning={eSigning}
					userIsSignee={userIsSignee}
					readOnly={forceReadOnly || readOnly}
					eSignees={eSignees.toList()}
					usersCache={usersCache}
					investors={investors}
					renderDropdown={this.renderSigneeDropdown}
					onExploreOnTangle={this.exploreOnTangle}
					bottomComponent={this.renderBottomInformation()}
					noPanel={noPanel}
					userHasSigned={userHasSigned}
					documentAllHaveSigned={documentAllHaveSigned}
					documentIsSigned={documentIsSigned}
					finalizationFalied={finalizationFalied}
					documentSealingFailed={
						documentAllHaveSigned && !documentIsSigned && finalizationFalied
					}
					marginLeft={marginLeft}
					marginTop={marginTop}
					marginRight={marginRight}
					marginBottom={marginBottom}
					recreateDocumentComponent={<EsignReacreateDocumentContainer />}
					signedFilePreviewComponent={
						<SignedDocumentFilePreviewContainer
							onDeleteSignedDocument={onDeleteSignedDocument}
						/>
					}
					mainControlsComponent={
						<MainProcessControlsContainer
							documentAllHaveSigned={documentAllHaveSigned}
							readOnly={forceReadOnly || readOnly}
							objType={objType}
						/>
					}
				/>

				{!forceReadOnly && !readOnly && (
					<>
						<DocumentRequestEsignContainer
							onCancel={this.onResetChanges}
							onCloseModal={this.onCloseRequestSigningModal}
							renderShareWithComponent={renderShareWithComponent}
							skipFilesView={skipFilesView}
							onSendSignRequest={onSendSignRequest}
							objType={objType}
						/>
					</>
				)}

				{this.renderEsignUsersModal()}
				{dialogElement}
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	const userId = store.user.getIn(["userObj", "id"]);
	const document = store.documents.get("document", Map());

	return {
		documentAllHaveSigned: DocumentHelper.getDocumentAllHaveSigned(document),
		documentIsSigned: DocumentHelper.getDocumentIsSigned(document),
		readOnly:
			!store.documents.getIn(["document", "ALLOW_UPDATE"]) ||
			store.documents.getIn(["document", "isDeleted"]),
		eSignees: store.documents.getIn(["document", "eSignees"], Map()),
		eSigning: store.documents.getIn(["document", "eSigning"]),
		isPdf: store.documents.getIn(["document", "file", "ext"]) === "pdf",
		documentId: store.documents.getIn(["document", "id"]),
		tangleUrl: store.documents.getIn(["document", "eSigningData", "tangleUrl"]),
		finalizationFalied: store.documents.getIn([
			"document",
			"eSigningData",
			"finalizationFalied",
		]),
		finialisedAt: store.documents.getIn([
			"document",
			"eSigningData",
			"finialisedAt",
		]),
		companyId: store.company.company.id,
		investors: store.investors.get("investorsCache"),
		usersCache: store.usersCache.get("usersCache"),
		i18n: store.i18n,
		userHasSigned:
			store.documents.getIn(["document", "eSignees", userId, "status"]) ===
			"SIGNED",
		userId,
	};
};

const mapActionsToProps = {
	fetchSimpleUsers,
	remindAboutESign,
	clearDocumentsToMerge,
	fetchAndCacheInvestors,
};

const EsignSignatoriesPanelContainerConnected = connect(
	mapStoreToProps,
	mapActionsToProps,
)(EsignSignatoriesPanelContainer);

export default withResolvedProps(() => {
	const { dialogElement, confirm } = useDialogModal();
	return { dialogElement, confirm };
})(EsignSignatoriesPanelContainerConnected);
