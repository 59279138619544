import { useEffect, useState } from "react";
import api from "@/modules/request.module.v2";
import useCompanyIdFromUrl from "./useCompanyIdFromUrl";

const useCreateStripeSetupIntent = () => {
	const companyId = useCompanyIdFromUrl();
	const [setupIntent, setSetupIntent] = useState(null);

	useEffect(() => {
		api
			.post("/subscriptions/setup-intent", undefined, {
				params: { companyId },
			})
			.then((response) => {
				setSetupIntent(response.data);
			});
	}, []);

	return setupIntent;
};
export default useCreateStripeSetupIntent;
