import React from "react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import Panel from "../../../../dumb-components/panel";
import { bool, func, string } from "prop-types";

const FormButtonArea = ({
	cancelDisabled = false,
	submitDisabled = false,
	submitButtonLabel,
	onCancelClick,
	onSubmitClick,
}) => {
	const { t } = useTranslation();

	return (
		<Panel className="sticky bottom-0 mb-0 rounded-t-none">
			<div className="flex justify-end px-6 gap-4">
				<Button
					variant="secondary"
					disabled={cancelDisabled}
					onClick={onCancelClick}
				>
					{t("shares.transactions.actions.cancel_transaction")}
				</Button>
				<Button disabled={submitDisabled} onClick={onSubmitClick}>
					{submitButtonLabel ?? t("tooltip.shares.action.accept_transaction")}
				</Button>
			</div>
		</Panel>
	);
};

FormButtonArea.propTypes = {
	cancelDisabled: bool,
	submitDisabled: bool,
	submitButtonLabel: string,
	onCancelClick: func.isRequired,
	onSubmitClick: func.isRequired,
};

export default FormButtonArea;
