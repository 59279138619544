import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import ICU from 'i18next-icu';
// import en from 'i18next-icu/locale-data/en';
// import sv from 'i18next-icu/locale-data/sv';

import translations from "./i18n/translations.json";
import { fromJS } from "immutable";

const data = {
	en: {},
	sv: {},
};

const msg = fromJS(translations);

msg.forEach((langs, id) => {
	langs.forEach((m, lang) => {
		data[lang][id] = m;
	});
});

i18n.use(initReactI18next).init({
	lng: "sv",
	fallbackLng: "en",
	debug: false,
	keySeparator: false,
	interpolation: {
		escapeValue: false, // not needed for react!!
	},
	resources: {
		en: {
			translation: data.en,
		},
		sv: {
			translation: data.sv,
		},
	},
	react: {
		transKeepBasicHtmlNodesFor: [
			"br",
			"strong",
			"i",
			"em",
			"u",
			"a",
			"li",
			"ul",
			"p",
			"s",
			"h1",
			"h2",
			"h3",
			"h4",
		],
	},
});

export default i18n;
