import React, { useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Tag, X } from "lucide-react";

import moment from "@/modules/moment.module";

import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import useCouponCode from "@/hooks/useCouponCode";
import useNotifications from "@/hooks/useNotifications";
import useSubscription from "@/hooks/useSubscription";
import useUpdateStripeCustomer from "@/hooks/useUpdateStripeCustomer";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import useDialogModal from "@/hooks/useDialogModal";
import { bool } from "prop-types";

const CouponField = ({ disabled }) => {
	const { t } = useTranslation();
	const { notify } = useNotifications();
	const { confirm, dialogElement } = useDialogModal();
	const [couponCode, setCouponCode] = useState();

	const companyId = useCompanyIdFromUrl();

	const { data: subscriptionRequest, isLoading: isLoadingSubscription } =
		useSubscription();
	const subscription = subscriptionRequest?.data;
	const existingDiscount = subscription?.stripeData?.discount;
	const existingCouponCode = existingDiscount?.coupon?.id;

	const {
		data: verifiedCouponCodeRequest,
		isLoading: isVerifyingCouponCode,
		error: couponCodeError,
	} = useCouponCode(couponCode);

	const verifiedCouponCode = verifiedCouponCodeRequest?.data;

	const { mutate: updateStripeCustomer, isPending: isUpdatingStripeCustomer } =
		useUpdateStripeCustomer(companyId);

	const onCouponError = () => {
		setCouponCode("");
		notify(
			"error",
			"subscriptions.coupon",
			"subscriptions.error.failed_to_verify_coupon",
		);
	};

	useEffect(() => {
		if (!couponCodeError) return;
		onCouponError();
	}, [couponCodeError]);

	useEffect(() => {
		if (!verifiedCouponCode?.id) return;

		updateStripeCustomer(
			{ coupon: verifiedCouponCode.id },
			{ onError: onCouponError },
		);
	}, [verifiedCouponCode?.id]);

	const onClickRemoveCouponCode = async () => {
		const isRemovalConfirmed = await confirm(
			t("subscriptions.coupon.confirm_removal.title"),
			<Trans i18nKey="subscriptions.coupon.confirm_removal.body" />,
			{ variant: "destructive" },
		);

		if (!isRemovalConfirmed) return;

		setCouponCode("");
		updateStripeCustomer({ coupon: null });
	};

	const isLoading =
		isVerifyingCouponCode || isLoadingSubscription || isUpdatingStripeCustomer;

	const discountEndTime =
		existingDiscount?.end && moment.unix(existingDiscount.end).format("LL");

	return (
		<>
			<div>
				{existingCouponCode ? (
					<div className="flex justify-between border border-success h-[50px]">
						<div className="flex items-center gap-2 p-3 text-success">
							<Tag size={16} />
							<span className="font-bold">{existingCouponCode} </span>
							<span className="text-foreground truncate">
								{discountEndTime
									? `${t("generic.expires")} ${discountEndTime}`
									: t("generic.never_expires")}
							</span>
						</div>
						<Button
							className="h-full"
							disabled={disabled}
							variant="ghost"
							onClick={onClickRemoveCouponCode}
						>
							<X size={16} />
						</Button>
					</div>
				) : (
					<Input
						className="h-[50px]"
						disabled={disabled || isLoading}
						value={couponCode}
						onChange={(evt) => setCouponCode(evt.target.value)}
						placeholder={t("subscriptions.account.placeholder.coupon")}
						isLoading={isLoading}
					/>
				)}
			</div>
			{dialogElement}
		</>
	);
};

CouponField.propTypes = {
	disabled: bool,
};

CouponField.defaultProps = {
	disabled: false,
};

export default CouponField;
