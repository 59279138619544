import React, { Component } from "react";
import { connect } from "react-redux";
import { List } from "immutable";

import NotifyMultipleUsersModalContainer from "../../generic-modals/notify-multiple-users-modal.container";

import { remindInvestors } from "../../../../actions/investors.actions";

import { REMIND_SHAREHOLDERS_EMAIL_TEMPLATE_MODAL } from "../../../../constants/modals";
import { EMAIL_TEMPLATES_INVITE_SHAREHOLDER_TO_JOIN_INVONO } from "/shared/constants";

class RemindShareholdersModalContainer extends Component {
	notify = (
		shareholdersToNotify,
		workingTemplate,
		doneCallbackFunc,
		errorCallbackFunc,
	) => {
		const { remindInvestors } = this.props;
		let investmentIds = List();

		shareholdersToNotify.forEach((shareholder) => {
			const investmentId = shareholder.get("investmentId");
			investmentIds = investmentIds.push(investmentId);
		});

		remindInvestors(
			investmentIds,
			workingTemplate,
			doneCallbackFunc,
			errorCallbackFunc,
		);
	};

	customVisibleInvestorsFilter = (investor) => {
		if (
			!investor.get("isDeletable") &&
			investor.getIn(["details", "numOfTotalShares"]) === 0
		) {
			return false;
		}

		return !investor.get("investorId");
	};

	render = () => {
		return (
			<NotifyMultipleUsersModalContainer
				modalName={REMIND_SHAREHOLDERS_EMAIL_TEMPLATE_MODAL}
				templateName={EMAIL_TEMPLATES_INVITE_SHAREHOLDER_TO_JOIN_INVONO}
				onSubmit={this.notify}
				customVisibleInvestorsFilter={this.customVisibleInvestorsFilter}
				includeInvestorsWithoutShares={true}
				includeUnregisteredUsers={true}
				externalsDisabled={true}
				successNotificatonTid="user_management.unregistered_shareholder_reminded_to_register"
				successNotificatonTidMultiple="user_management.unregistered_shareholders_reminded_to_register"
				errorNotificationTid="user_management.error.remind_unregistered_shareholders"
			/>
		);
	};
}

const mapStoreToProps = () => {
	return {};
};

const mapActionsToProps = {
	remindInvestors,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(RemindShareholdersModalContainer);
