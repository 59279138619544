import React, { Component } from "react";
import { connect } from "react-redux";
import { string, bool } from "prop-types";
import { Map, fromJS } from "immutable";
import { v1 } from "uuid";
import i18nHelper from "../../components/helpers/i18n.helper";

import DropdownMenuContainer from "../shared/dropdown-menu.container";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import DropdownItem from "../../dumb-components/shared/dropdown-item/dropdown-item";
import EditorContainer from "../shared/editor.container";
import HandleEmailTemplate from "../../dumb-components/notify-by-templates/handle-email-template";
import LanguageSwitcher from "../../dumb-components/navbar/language-switcher-menu/language-switcher-menu";
import Text from "../../dumb-components/shared/text/text";

import { Input } from "@/components/ui/input";
import i18n from "@/i18n";

const { t } = i18n;

import {
	fetchEmailTemplate,
	saveEmailTemplate,
	resetEmailTemplate,
	updateEmailTemplateLocal,
} from "../../actions/email-templates.actions";

const LANGUAGE_OPTIONS = fromJS([
	{ title: "language_switcher.language.swedish", value: "sv-SE" },
	{ title: "language_switcher.language.english", value: "en-GB" },
]);

const DEFAULT_TEMPLATE_LANGUAGES = ["sv", "en"];

class HandleEmailTemplateContainer extends Component {
	state = {
		templateLanguage: null,
	};

	static propTypes = {
		templateName: string.isRequired,
		disabled: bool,
	};

	languageDropdownRef = null;
	moreActionDropdownRef = null;

	componentDidMount = () => {
		const { fetchEmailTemplate, templateName } = this.props;

		fetchEmailTemplate(templateName, (returnData) => {
			this.setTemplateLanguage();

			setImmediate(() => {
				this.setWorkingTemplate(returnData);
			});
		});
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { emailTemplates, emailTemplatesDefault, userLanguage } = this.props;
		const { templateLanguage } = this.state;

		// Email template was removed
		if (emailTemplates.size === prevProps.emailTemplates.size - 1) {
			this.setWorkingTemplate(Map({ emailTemplates, emailTemplatesDefault }));
		}

		// Language was switched, select correct working template
		if (templateLanguage && templateLanguage !== prevState.templateLanguage) {
			this.setWorkingTemplate(Map({ emailTemplates, emailTemplatesDefault }));
		}

		// User language loaded in
		if (userLanguage && userLanguage !== prevProps.userLanguage) {
			this.setTemplateLanguage();
		}
	};

	setWorkingTemplate = (returnData) => {
		const { templateName, updateEmailTemplateLocal } = this.props;
		const { templateLanguage } = this.state;

		if (!templateLanguage) {
			return;
		}

		const emailTemplates = returnData.get("emailTemplates");
		const emailTemplatesDefault = returnData.get("emailTemplatesDefault");
		let foundTemplate = null;

		const findTemplateInArray = (templates) => {
			if (!templates) {
				return false;
			}

			return templates.find(
				(template) =>
					template.get("name") === templateName &&
					template.get("language") === templateLanguage,
			);
		};

		foundTemplate = findTemplateInArray(emailTemplates);

		if (!foundTemplate) {
			foundTemplate = findTemplateInArray(emailTemplatesDefault);
		}

		if (foundTemplate) {
			foundTemplate = foundTemplate.set("id", v1());
			updateEmailTemplateLocal(foundTemplate);
		}
	};

	setTemplateLanguage = (inputLanguage) => {
		const { userLanguage } = this.props;
		let templateLanguage;

		if (inputLanguage) {
			templateLanguage = inputLanguage.split("-")[0];
		} else if (userLanguage) {
			templateLanguage = userLanguage.split("-")[0];
		} else {
			templateLanguage = i18nHelper.getBrowserLanguage().split("-")[0];

			if (!DEFAULT_TEMPLATE_LANGUAGES.includes(templateLanguage)) {
				templateLanguage = "en";
			}
		}

		this.setState({ templateLanguage });
	};

	onChange = (fieldName, value) => {
		const { updateEmailTemplateLocal } = this.props;
		let { workingTemplate } = this.props;

		workingTemplate = workingTemplate.set(fieldName, value);

		updateEmailTemplateLocal(workingTemplate);
	};

	saveTemplates = () => {
		const { saveEmailTemplate, emailTemplates } = this.props;

		emailTemplates.forEach((template) => {
			saveEmailTemplate(template);
		});
	};

	resetTemplate = () => {
		const { resetEmailTemplate, workingTemplate } = this.props;

		resetEmailTemplate(workingTemplate);
	};

	renderDropdownItem = (item, index) => {
		return (
			<DropdownItem
				key={index}
				rightComponent={<Text tid={item.get("title")}></Text>}
				onClick={() => {
					this.setTemplateLanguage(item.get("value"));
					this.languageDropdownRef.onToggleMenu();
				}}
			/>
		);
	};

	renderLanguageSwitcher = (readOnly) => {
		const { userLanguage } = this.props;
		const { templateLanguage } = this.state;
		const language = templateLanguage || userLanguage;

		if (!language) {
			return null;
		}

		return (
			<DropdownMenuContainer
				halignMenu="right"
				ref={(r) => (this.languageDropdownRef = r)}
				renderRaw={<LanguageSwitcher language={language} disabled={readOnly} />}
				disabled={readOnly}
			>
				{LANGUAGE_OPTIONS.map(this.renderDropdownItem)}
			</DropdownMenuContainer>
		);
	};

	renderDropdown = (readOnly) => {
		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisV"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				ref={(r) => (this.moreActionDropdownRef = r)}
				disabled={readOnly}
				noMaxWidth
				withPortal
				inline
			>
				<DropdownIconItem
					tid="email_templates.modal.more_action.save_template"
					icon="faSave"
					onClick={() => {
						this.saveTemplates();
						this.moreActionDropdownRef.onToggleMenu();
					}}
				/>

				<DropdownIconItem
					tid="email_templates.modal.more_action.reset_template"
					icon="faHistory"
					onClick={() => {
						this.resetTemplate();
						this.moreActionDropdownRef.onToggleMenu();
					}}
				/>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const { workingTemplate, disabled } = this.props;
		const templateBody = workingTemplate.get("body");
		const id = workingTemplate.get("id");
		const readOnly = disabled || !templateBody;

		return (
			<HandleEmailTemplate
				languageSwitcherComponent={this.renderLanguageSwitcher(readOnly)}
				dropdownComponent={this.renderDropdown(readOnly)}
				subjectFieldComponent={
					<label className="mb-6 w-full p-2">
						{t("subject")}
						<Input
							onChange={(evt) => this.onChange("subject", evt.target.value)}
							value={workingTemplate.get("subject")}
						/>
					</label>
				}
				editorComponent={
					<EditorContainer
						fieldName="body"
						onChange={this.onChange}
						contentState={templateBody}
						readOnly={readOnly}
						objId={id}
						inline={true}
					/>
				}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		workingTemplate: store.emailTemplates.get("workingTemplate"),
		emailTemplates: store.emailTemplates.get("emailTemplates"),
		emailTemplatesDefault: store.emailTemplates.get("emailTemplatesDefault"),
		userLanguage: store.user.getIn(["userObj", "siteSettings", "language"]),
	};
};

const mapActionsToProps = {
	fetchEmailTemplate,
	saveEmailTemplate,
	resetEmailTemplate,
	updateEmailTemplateLocal,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(HandleEmailTemplateContainer);
