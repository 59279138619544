import React, { useRef, useState } from "react";
import DialogModal from "../dialogModal";
import i18n from "../../i18n";
import StripePaymentDetailsForm from "@/components/subscriptions/stripePaymentDetailsForm";
import StripeElements from "@/components/subscriptions/stripeElements";
import { Button } from "@/components/ui/button";
import useStripeCreateSetupIntent from "@/hooks/useCreateStripeSetupIntent";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import LoadingPanel from "../loadingPanel";
import useUpdateStripeCustomer from "@/hooks/useUpdateStripeCustomer";

const { t } = i18n;

export const AddPaymentMethodButton = ({ onClick, ...props }) => (
	<Button className="m-auto" variant="secondary" onClick={onClick} {...props}>
		{t("subscriptions.add_payment_method")}
	</Button>
);

const AddPaymentMethodModal = ({ onCancel }) => {
	const setupIntent = useStripeCreateSetupIntent();
	const companyId = useCompanyIdFromUrl();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const submitButtonRef = useRef();

	const {
		mutateAsync: updateStripeCustomer,
		isPending: isUpdatingCustomerPending,
	} = useUpdateStripeCustomer(companyId);

	const onAddPaymentMethod = async (stripeResponse) => {
		const paymentMethodId = stripeResponse?.setupIntent?.payment_method;

		paymentMethodId &&
			(await updateStripeCustomer({
				invoice_settings: {
					default_payment_method: paymentMethodId,
				},
			}));

		onCancel();
	};

	return (
		<DialogModal
			open
			title={t("subscriptions.add_payment_method")}
			submitText={t("subscriptions.add_payment_method")}
			bodyRenderer={() => (
				<div className="min-h-[300px] w-full">
					{setupIntent && companyId && !isUpdatingCustomerPending ? (
						<StripeElements setupIntent={setupIntent}>
							<StripePaymentDetailsForm
								companyId={companyId}
								submitButtonRef={submitButtonRef}
								onLoadingChanged={setIsSubmitting}
								onComplete={onAddPaymentMethod}
							/>
						</StripeElements>
					) : (
						<LoadingPanel />
					)}
				</div>
			)}
			onSubmit={() => {
				submitButtonRef.current?.click?.();
			}}
			isSubmitting={isSubmitting}
			onCancel={onCancel}
		/>
	);
};

AddPaymentMethodModal.propTypes = {
	onCancel: DialogModal.propTypes.onCancel,
};

AddPaymentMethodModal.defaultProps = {
	onCancel: DialogModal.defaultProps.onCancel,
};

export default AddPaymentMethodModal;
