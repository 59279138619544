import React, { createContext, useContext } from "react";
import Moment from "@/modules/moment.module";
import UserAvatar from "./userAvatar";
import { cn } from "./utils";
import DropdownMenu from "./dropdownMenu";
import { Button } from "./ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Calendar, EllipsisVertical } from "lucide-react";
import withTooltip from "@/hocs/withTooltip";
import Tooltip from "./tooltip";

const ItemListRowContext = createContext(null);

export const ItemList = ({ children, className }) => {
	return (
		<div className={cn("flex flex-col shadow rounded-lg", className)}>
			{children}
		</div>
	);
};

export const ItemListRow = ({ children, isSelected, isChecked, onClick }) => {
	return (
		<ItemListRowContext.Provider value={{ isSelected, isChecked }}>
			<div
				onClick={onClick}
				className={cn(
					"flex gap-4 items-center justify-between bg-background border border-edge p-4 border-b-0 first:rounded-t-lg last:rounded-b-lg last:border-b-[1px]",
					{
						"cursor-pointer": typeof onClick === "function",
						"bg-gradient-to-r from-primary/20 to-background shadow-[white_inset_0_0_50px]":
							isSelected || isChecked,
						"border-2 border-primary !border-b-2": isSelected,
					},
				)}
			>
				{children}
			</div>
		</ItemListRowContext.Provider>
	);
};

export const ItemColumnBase = ({ children, className }) => {
	return (
		<div
			className={cn(
				"[&_svg]:text-foreground/25 flex w-full justify-start",
				className,
			)}
		>
			{children}
		</div>
	);
};

ItemColumnBase.displayName = "ItemColumnBase";

export const ItemColumn = withTooltip()(ItemColumnBase);

const Date = ({ children, format = "L", className }) => {
	return (
		<Tooltip value={Moment(children).format("llll")}>
			<div
				className={cn("inline-flex gap-2 overflow-hidden truncate", className)}
			>
				<Calendar size={16} className="text-gray-400" />
				{Moment(children).format(format)}
			</div>
		</Tooltip>
	);
};

const User = ({ id, name, extra, className }) => {
	return (
		<div className={cn("flex gap-4", className)}>
			<UserAvatar userId={id} userName={name} />
			<div className="flex justify-center flex-col">
				<div className="font-semibold">{name}</div>
				{extra?.length && <div className="text-foreground/60">{extra}</div>}
			</div>
		</div>
	);
};

const Actions = ({ options }) => (
	<DropdownMenu
		trigger={
			<Button variant="ghost">
				<EllipsisVertical />
			</Button>
		}
		options={options}
	/>
);

const Check = () => {
	const context = useContext(ItemListRowContext);

	return <Checkbox checked={context.isChecked} />;
};

ItemColumn.Date = Date;
ItemColumn.User = User;
ItemColumn.Actions = Actions;
ItemColumn.Checkbox = Check;
