import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool, string } from "prop-types";
import { List } from "immutable";
import ShareholdersToolbarControls from "../../dumb-components/shares/shareholders-toolbar-controls/shareholders-toolbar-controls";
import DropdownMenuContainer from "../../containers/shared/dropdown-menu.container";
import Tooltip from "../../dumb-components/shared/tooltip/tooltip";
import DropdownIconItem from "../../dumb-components/shared/dropdown-item/dropdown-icon-item";
import InvestorRelinkModalBtnController from "../../containers/shares/investor-relink-modal-btn-controller.container";
import {
	unlinkInvestor,
	listInvestors,
	fetchInvestor,
} from "../../actions/investors.actions";
import { dismissBlock } from "../../actions/user.actions";
import { openModal } from "../../actions/modals.actions";
import {
	createCapitalInsurance,
	listCapitalInsurances,
} from "../../actions/capital-insurance.actions";
import { MANAGE_DEPO_MODAL } from "../../constants/modals";

import CapitalInsuranceModalContainer from "../shares/capital-insurance-modal.container";
import ManageDepoModalContainer from "./modals/manage-depo-modal.container";
import history, { getLocation } from "../../interfaces/history";
import withResolvedProps from "../../hocs/withResolvedProps";
import useSubscriptionHelper from "../../hooks/useSubscriptionHelper";

const MORE_ACTION_TOOLTIP_STATES = {
	default: {
		activeState: "btnMoreActions",
	},
	noShareholderSelected: {
		tid: "shares.shareholders.details.toolbar.no_shareholder_selected",
		delayShow: "instant",
	},
};

const PREVIEW_SHAREHOLDER_TOOLTIP_STATES = {
	shareholderLimitReached: {
		tid: "shares.shareholders.details.toolbar.shareholder_limit_reached",
		delayShow: "instant",
	},
	noShares: {
		tid: "shareholders_preview_disabled_tid",
		delayShow: "instant",
	},
};

const INITIAL_STATE = {
	isCopyEmailModalOpen: false,
	insuranceCompanyModalIsOpen: false,
	depoModalIsOpen: false,
	capitalInsuranceModalContainerisLoading: false,
	isGeneratingExcelExport: false,
};

class TransactionToolbarControlsContainer extends Component {
	static propTypes = {
		onDeleteShareholder: func,
		deleteShareholderDisabled: bool,
		isLinked: bool,
		selectedInvestmentId: string,
	};

	static defaultProps = {
		deleteShareholderDisabled: false,
		isLinked: false,
	};

	state = INITIAL_STATE;

	dropzoneRef = null;

	componentDidMount = () => {
		const { listCapitalInsurances } = this.props;

		listCapitalInsurances();
	};

	openAddInsuranceCompanyModal = () => {
		this.setState({ insuranceCompanyModalIsOpen: true });
	};

	openManageDeposModal = () => {
		this.props.openModal(MANAGE_DEPO_MODAL);
	};

	openShareholderPreview = () => {
		const { selectedInvestmentId } = this.props;
		const location = getLocation();

		if (!location) {
			throw new Error("No location object");
		}

		const splitted = location.pathname.split("/");
		delete splitted[splitted.length - 1];
		const url = `${splitted.join(
			"/",
		)}preview/?investmentId=${selectedInvestmentId}`;

		history.push(url);
	};

	unlinkInvestor = () => {
		const { selectedInvestmentId, unlinkInvestor } = this.props;

		this.unlinkBtnRef && this.unlinkBtnRef.onToggleMenu();

		if (!selectedInvestmentId) {
			return null;
		}

		unlinkInvestor(selectedInvestmentId);
	};

	closeConfirm = () => {
		this.setState(INITIAL_STATE);
	};

	renderUnlinkDropdown = () => {
		const { onDeleteShareholder, isLinked } = this.props;
		const dropdownDisabled = !onDeleteShareholder ? true : false;
		const tooltipDelayShow = dropdownDisabled ? "instant" : undefined;
		const tooltipTid = dropdownDisabled
			? "shares.shareholders.details.toolbar.no_shareholder_selected"
			: "shares.shareholders.details.toolbar.settings.unlink.tooltip";

		return (
			<DropdownMenuContainer
				btnIcon="faUserCog"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				tooltipTid={tooltipTid}
				tooltipDelayShow={tooltipDelayShow}
				noMaxWidth={true}
				withPortal
				disabled={dropdownDisabled}
				ref={(ref) => (this.unlinkBtnRef = ref)}
			>
				<InvestorRelinkModalBtnController
					moreActionBtnRef={this.unlinkBtnRef}
				/>

				<DropdownIconItem
					tid="investor.unlink.dropdown.item.title"
					icon="faUnlink"
					disabled={!isLinked}
					onClick={this.unlinkInvestor}
				/>
			</DropdownMenuContainer>
		);
	};

	renderMoreActionDropdown = () => {
		const { onDeleteShareholder, deleteShareholderDisabled, onCancel } =
			this.props;
		// onDeleteShareholder can be used to determine if an shareholder is selected
		const dropdownDisabled = !onCancel && !onDeleteShareholder;
		let activeState;

		if (dropdownDisabled) {
			activeState = "noShareholderSelected";
		}

		return (
			<DropdownMenuContainer
				btnIcon="faEllipsisH"
				halignMenu="right"
				btnMode="transparent-icon"
				ref={(ref) => (this.moreActionBtnRef = ref)}
				tooltipStates={MORE_ACTION_TOOLTIP_STATES}
				noMaxWidth={true}
				disabled={dropdownDisabled}
				tooltipActiveState={activeState}
			>
				{onCancel && (
					<DropdownIconItem
						tid="shares.share_register.new_investor.cancel"
						icon="faTimes"
						onClick={onCancel}
					/>
				)}

				{onDeleteShareholder && (
					<Tooltip
						tid="tooltip.shares.shareholders.action.delete.has_had_shares"
						delayShow="instant"
						active={deleteShareholderDisabled}
					>
						<DropdownIconItem
							tid="delete_shareholder"
							icon="faTrashAlt"
							disabled={deleteShareholderDisabled}
							onClick={onDeleteShareholder}
						/>
					</Tooltip>
				)}
			</DropdownMenuContainer>
		);
	};

	checkIfInsuranceCompanyExists = (selectedInsuranceCompany) => {
		const { capitalInsurances } = this.props;
		const orgNumber = selectedInsuranceCompany.getIn(["company", "orgNumber"]);
		const companyExists = capitalInsurances.some((company) => {
			return company.get("organisationNumber").includes(orgNumber);
		});

		return companyExists;
	};

	closeAddInsuranceCompanyModal = (companyId, selectedInsuranceCompany) => {
		const { createCapitalInsurance } = this.props;
		const companyExists =
			selectedInsuranceCompany &&
			this.checkIfInsuranceCompanyExists(selectedInsuranceCompany);

		if (companyId && selectedInsuranceCompany && !companyExists) {
			this.setState({ capitalInsuranceModalContainerisLoading: true });
			createCapitalInsurance(selectedInsuranceCompany, () => {
				this.setState({ capitalInsuranceModalContainerisLoading: false });
			});
		}

		this.setState({ insuranceCompanyModalIsOpen: false });
	};

	addCapitalInsuranceDropdown = () => {
		return (
			<DropdownMenuContainer
				btnIcon="faBooks"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				disabled={false}
				noMaxWidth={true}
			>
				<DropdownIconItem
					icon="faHouseFlood"
					tid="shares.shareholders.add_capital_insurance.btn.tooltip"
					onClick={this.openAddInsuranceCompanyModal}
				/>
				<DropdownIconItem
					icon="faFileInvoice"
					tid="shareholders.toolbar.dropdown.manage_depo.btn"
					onClick={this.openManageDeposModal}
				/>
			</DropdownMenuContainer>
		);
	};

	renderShareholderPeekDropdown = () => {
		const { selectedInvestor, maxNumberOfShareholders, numOfInvestors } =
			this.props;
		const totalShares = selectedInvestor
			? selectedInvestor.getIn(["details", "numOfTotalShares"])
			: 0;
		const hasShares = totalShares && totalShares > 0;
		const hasReachedShareholderLimit =
			numOfInvestors >= maxNumberOfShareholders;

		let activeState;
		let disabled = false;

		if (hasReachedShareholderLimit) {
			activeState = "shareholderLimitReached";
			disabled = true;
		} else if (!hasShares) {
			activeState = "noShares";
			disabled = true;
		}

		return (
			<DropdownMenuContainer
				btnIcon="faBookUser"
				halignMenu="right"
				btnMode="transparent-icon"
				transparentIconButtonSize="sml"
				disabled={false}
				noMaxWidth={true}
			>
				<Tooltip
					states={PREVIEW_SHAREHOLDER_TOOLTIP_STATES}
					activeState={activeState}
				>
					<DropdownIconItem
						disabled={disabled}
						icon="faExpandArrowsAlt"
						tid="shares.shareholders.toolbar.peek.menu_item.preview_cap_table"
						onClick={this.openShareholderPreview}
					/>
				</Tooltip>
			</DropdownMenuContainer>
		);
	};

	render = () => {
		const {
			insuranceCompanyModalIsOpen,
			capitalInsuranceModalContainerisLoading,
		} = this.state;

		return (
			<div>
				<ManageDepoModalContainer />
				<CapitalInsuranceModalContainer
					isModalOpen={insuranceCompanyModalIsOpen}
					closeModal={this.closeAddInsuranceCompanyModal}
					isLoading={capitalInsuranceModalContainerisLoading}
				/>
				<ShareholdersToolbarControls
					addCapitalInsuranceRenderer={this.addCapitalInsuranceDropdown}
					moreActionsDropdownRenderer={this.renderMoreActionDropdown}
					unlinkDropdownRenderer={this.renderUnlinkDropdown}
					shareholderPeekDropdownRenderer={this.renderShareholderPeekDropdown}
				/>
			</div>
		);
	};
}

function mapStateToProps(state) {
	return {
		selectedInvestor: state.investors.get("selectedInvestor"),
		capitalInsurances: state.capitalInsurance.get("capitalInsurances"),
		numOfInvestors: (state.investors.get("list", List()) ?? List()).size,
	};
}
const mapActionsToProps = {
	unlinkInvestor,
	listInvestors,
	fetchInvestor,
	dismissBlock,
	openModal,
	createCapitalInsurance,
	listCapitalInsurances,
};
const TransactionToolbarControlsContainerConnected = connect(
	mapStateToProps,
	mapActionsToProps,
)(TransactionToolbarControlsContainer);

export default withResolvedProps(() => {
	const { data: subscriptionHelperQuery } = useSubscriptionHelper();
	const subscriptionHelper = subscriptionHelperQuery?.data;
	return {
		maxNumberOfShareholders: subscriptionHelper?.maxNumberOfShareholders,
	};
})(TransactionToolbarControlsContainerConnected);
