import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	AddressElement,
	PaymentElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";

import useCompany from "@/hooks/useCompany";
import useMe from "@/hooks/users/useMe";
import LoadingPanel from "../loadingPanel";
import Alert from "@/components/alert";
import { useTranslation } from "react-i18next";

const StripePaymentDetailsForm = ({
	companyId,
	submitButtonRef,
	onLoadingChanged,
	onComplete,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const { t } = useTranslation();
	const [stripeErrorMessage, setStripeErrorMessage] = useState();

	const { data: companyQuery, isLoading: isCompanyLoading } =
		useCompany(companyId);

	const { data: meQuery, isLoading: isMeLoading } = useMe();

	const onSubmitForm = async (evt) => {
		evt.preventDefault();

		onLoadingChanged(true);

		const results = await stripe.confirmSetup({
			elements,
			redirect: "if_required",
		});

		if (results.error) {
			setStripeErrorMessage(results.error?.message);
			onLoadingChanged(false);
		} else {
			onComplete?.(results);
		}
	};

	if (isCompanyLoading || isMeLoading) return <LoadingPanel />;

	const companyAddress = companyQuery?.data?.addresses?.find?.(
		(address) => address.type === "padr",
	);

	const name = meQuery?.data?.name;

	const billingDetailsDefaultValues = companyAddress
		? {
				name,
				address: {
					line1: companyAddress.street,
					city: companyAddress.city,
					postal_code: companyAddress.postalCode,
					country: companyAddress.country,
				},
		  }
		: {};

	return (
		<form onSubmit={onSubmitForm}>
			{stripeErrorMessage && (
				<Alert
					className="mb-8"
					variant="destructive"
					title={t("subscriptions.stripe_error")}
					message={stripeErrorMessage}
				/>
			)}
			<div className="flex w-full justify-around">
				<div>
					<PaymentElement />
				</div>
				<div>
					<AddressElement
						options={{
							mode: "billing",
							defaultValues: billingDetailsDefaultValues,
						}}
					/>
					{/* Hidden submit button to be triggered via `submitButtonRef` by consuming component */}
					<button
						type="submit"
						ref={submitButtonRef}
						style={{ display: "none" }}
					/>
				</div>
			</div>
		</form>
	);
};

StripePaymentDetailsForm.propTypes = {
	companyId: PropTypes.string.isRequired,
	submitButtonRef: PropTypes.object,
	onLoadingChanged: PropTypes.func,
};

StripePaymentDetailsForm.defaultProps = {
	onLoadingChanged: () => {},
};

export default StripePaymentDetailsForm;
