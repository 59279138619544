import React, { useState } from "react";
import { bool, func, oneOf, node, oneOfType, string } from "prop-types";
import { cva } from "class-variance-authority";

import { TriangleAlert, Check } from "lucide-react";
import { cn } from "./utils";

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogBody,
} from "./ui/dialog";

import { Button } from "./ui/button";
import { useTranslation } from "react-i18next";

const dialogContentVariants = cva("", {
	variants: {
		size: {
			sm: "max-w-[600px]",
			md: "max-w-[800px]",
			lg: "max-w-[1000px]",
			xl: "max-w-[1200px]",
		},
	},
});

const defaultSubmitButtonRenderer = (
	{ submitText, onSubmit, isSubmitting, submitDisabled, variant },
	submitValue,
) =>
	submitText === null ? null : (
		<Button
			type="submit"
			onClick={() => {
				onSubmit(submitValue);
			}}
			disabled={
				isSubmitting ||
				(typeof submitDisabled === "function"
					? submitDisabled(submitValue)
					: submitDisabled)
			}
			isLoading={isSubmitting}
			variant={variant === "destructive" ? "destructive" : "default"}
		>
			{submitText}
		</Button>
	);

const defaultCancelButtonRenderer = (
	{ onCancel, isSubmitting, cancelDisabled, cancelText },
	submitValue,
) => (
	<Button
		variant="ghost"
		onClick={onCancel}
		disabled={
			isSubmitting ||
			(typeof cancelDisabled === "function"
				? cancelDisabled(submitValue)
				: cancelDisabled)
		}
	>
		{cancelText}
	</Button>
);

const defaultFooterRenderer = (props, submitValue) => {
	const { cancelButtonRenderer, submitButtonRenderer } = props;

	return (
		<>
			{cancelButtonRenderer?.(props, submitValue)}
			{submitButtonRenderer?.(props, submitValue)}
		</>
	);
};

const DialogModal = (_props) => {
	const { t } = useTranslation();

	// Apply default translations to props
	// (can not be done outside of component due to i18n hook)
	const props = {
		submitText: t("dialog.generic.submit"),
		cancelText: t("dialog.generic.cancel"),
		..._props,
	};

	const [submitValue, setSubmitValue] = useState();

	const {
		borderless,
		className,
		open,
		footerRenderer,
		bodyRenderer,
		title,
		description,
		onCancel,
		hideCloseButton,
		size,
		variant,
		icon: _icon,
	} = props;

	const icon =
		_icon ??
		{
			success: <Check className="text-success" />,
			destructive: <TriangleAlert className="text-destructive" />,
			warning: <TriangleAlert className="text-warning" />,
		}[variant];

	return (
		<Dialog open={open}>
			<DialogContent
				onClose={onCancel}
				hideCloseButton={hideCloseButton}
				className={cn(className, dialogContentVariants({ size }))}
			>
				<DialogHeader className={cn({ "border-none": borderless })}>
					<DialogTitle className="flex gap-3 items-center">
						{icon}
						{title}
					</DialogTitle>
					{description && <DialogDescription>{description}</DialogDescription>}
				</DialogHeader>
				<DialogBody>
					{bodyRenderer(props, setSubmitValue, submitValue)}
				</DialogBody>
				<DialogFooter className={cn({ "border-none": borderless })}>
					{footerRenderer(props, submitValue)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

DialogModal.displayName = "DialogModal";

DialogModal.propTypes = {
	title: string,
	open: bool,
	borderless: bool,
	className: string,
	description: string,
	footerRenderer: func,
	bodyRenderer: func,
	submitButtonRenderer: func,
	cancelButtonRenderer: func,
	onCancel: func,
	onSubmit: func,
	submitText: oneOfType([string, null]),
	cancelText: string,
	submitDisabled: oneOfType([bool, func]),
	cancelDisabled: oneOfType([bool, func]),
	isSubmitting: bool,
	hideCloseButton: bool,
	size: oneOf(["sm", "md", "lg"]),
	variant: oneOf(["default", "success", "warning", "destructive"]),
	icon: node,
};

DialogModal.defaultProps = {
	// `submitText` default is set in the component
	// `cancelText` default is set in the component
	title: "Are you sure?",
	footerRenderer: defaultFooterRenderer,
	bodyRenderer: () => "This is a dialog body.",
	submitButtonRenderer: defaultSubmitButtonRenderer,
	cancelButtonRenderer: defaultCancelButtonRenderer,
	hideCloseButton: false,
	size: "md",
	variant: "default",
	open: true,
	submitDisabled: false,
	cancelDisabled: false,
	onCancel: () => {},
	onSubmit: () => {},
};

export default DialogModal;
