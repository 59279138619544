import { useQuery } from "@tanstack/react-query";
import apiClient from "@/modules/request.module.v2";

export default (queryOptions = {}) => {
	return useQuery({
		queryKey: ["me"],
		staleTime: 1000 * 60 * 2, // 2 minutes,
		queryFn: async () => {
			const response = await apiClient.get(`users/me`);

			// Identify user in Clarity
			const userId = response?.data?.id;
			if (userId) {
				window.clarity?.("identify", userId);
			}

			return response;
		},
		...queryOptions,
	});
};
