import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func, bool } from "prop-types";
import { Map } from "immutable";

import StepsModal from "../../../dumb-components/shared/modal/steps-modal";
import ModalInnerWrapper from "../../../dumb-components/shared/modal/modal-inner-wrapper";
import FooterRightControls from "../../../dumb-components/shared/modal/footer-right-controls";
import { TransparentButton } from "../../../dumb-components/shared/button-v2";
import PreviousLeftButton from "../../../dumb-components/shared/modal/previous-left-button";
import HandleEmailTemplateContainer from "../handle-email-template.container";
import { PeopleModalProvider } from "../../shared/people-modal/people-modal.provider";
import {
	PeopleModalHeader,
	getOnlyTabs,
} from "../../shared/people-modal/people-modal-header";
import { TeamsAndInvestorsListContainer } from "../../shared/people-modal/teams-and-investors-list/teams-and-investors-list.container";

import { closeModal } from "../../../actions/modals.actions";
import {
	addInfoNotification,
	addErrorNotification,
} from "../../../actions/notify.actions";

import {
	TAB_TEAMS,
	TAB_SHAREHOLDERS,
	TAB_EXTERNAL_USERS,
	TAB_TEAMS_AND_SHAREHOLDES,
} from "../../shared/select-user-modal/select-user-modal.constants";
import { NotifyMultipleUsersModalFooter } from "../modal-footer/notify-multiple-users-modal-footer";

const INITIAL_STATE = {
	step: 0,
	usersToNotify: Map(),
	activeTab: null,
	isLoading: false,
	isOpen: false,
};

class NotifyMultipleUsersModalContainer extends Component {
	state = INITIAL_STATE;

	static propTypes = {
		modalName: string.isRequired,
		templateName: string.isRequired,
		onSubmit: func.isRequired,
		btnSubmitTid: string,
		btnCloseTid: string,
		modalTitle: string,
		membersDisabled: bool,
		investorsDisabled: bool,
		externalsDisabled: bool,
		successNotificatonTid: string,
		successNotificatonTidMultiple: string,
		errorNotificationTid: string.isRequired,
		disableMemberFunction: func,
		disableShareholderFunction: func,
		includeUnregisteredUsers: bool,
		includeInvestorsWithoutShares: bool,
		initiallySelectAllMembers: bool,
		initiallySelectAllShareholders: bool,
		customVisibleInvestorsFilter: func,
	};

	static defaultProps = {
		btnSubmitTid: "generic.send",
		btnCloseTid: "generic.form.close",
		modalTitle: "notify_multiple_users.generic.modal.title",
	};

	componentDidMount = () => {
		this.initState();
	};

	componentDidUpdate = (prevProps) => {
		const { isOpen } = this.props;

		if (!isOpen && isOpen !== prevProps.isOpen) {
			this.initState();
		}
	};

	initState = () => {
		const { membersDisabled, investorsDisabled } = this.props;
		const firstState = INITIAL_STATE;

		if (!membersDisabled) {
			firstState.activeTab = TAB_TEAMS;
		} else if (!investorsDisabled) {
			firstState.activeTab = TAB_SHAREHOLDERS;
		} else {
			firstState.activeTab = TAB_EXTERNAL_USERS;
		}

		this.setState(firstState);
	};

	onFinish = () => {
		const { addInfoNotification } = this.props;
		const notificationTid = this.getSuccessNotificatonTid();

		if (notificationTid) {
			addInfoNotification({ tid: notificationTid });
		}

		this.closeModal();
	};

	closeModal = () => {
		this.props.closeModal();
	};

	stopLoading = () => {
		this.setState({ isLoading: false });
	};

	goToFirstStep = () => {
		this.setState({ step: 0 });
	};

	goToHandleEmailStep = () => {
		this.setState({ step: 1 });
	};

	changeActiveTab = (activeTab) => {
		this.setState({ activeTab });
	};

	changeUsersToNotify = (usersToNotify) => {
		this.setState({ usersToNotify });
	};

	submit = () => {
		const { onSubmit, workingTemplate } = this.props;
		const { usersToNotify } = this.state;

		this.setState({ isLoading: true });

		// 1.. 2.. 3. Done callback 4. error callback
		onSubmit &&
			onSubmit(usersToNotify, workingTemplate, this.onFinish, this.onError);
	};

	onError = () => {
		const { addErrorNotification, errorNotificationTid } = this.props;

		addErrorNotification(errorNotificationTid);
		this.stopLoading();
	};

	getSuccessNotificatonTid = () => {
		const { successNotificatonTid, successNotificatonTidMultiple } = this.props;
		const { usersToNotify } = this.state;
		if (usersToNotify.size > 1 && successNotificatonTidMultiple) {
			return successNotificatonTidMultiple;
		}
		return successNotificatonTid;
	};

	getSteps = () => {
		const {
			templateName,
			modalTitle,
			btnSubmitTid,
			membersDisabled,
			investorsDisabled,
			disableMemberFunction,
			disableShareholderFunction,
			includeUnregisteredUsers,
			includeInvestorsWithoutShares,
			initiallySelectAllMembers,
			initiallySelectAllShareholders,
		} = this.props;

		const { step, isLoading } = this.state;

		return [
			{
				header: {
					component: (
						<PeopleModalHeader
							title={modalTitle}
							tabs={getOnlyTabs([TAB_TEAMS_AND_SHAREHOLDES])}
						/>
					),
				},
				body: (
					<ModalInnerWrapper noScrollView fullHeight>
						{step === 0 && (
							<TeamsAndInvestorsListContainer
								disableMemberFunction={disableMemberFunction}
								disableInvestorFunction={disableShareholderFunction}
								includeUnregisteredUsers={includeUnregisteredUsers}
								includeInvestorsWithoutShares={includeInvestorsWithoutShares}
								initiallySelectAll={
									initiallySelectAllMembers || initiallySelectAllShareholders
								}
								showOnlyMembers={investorsDisabled}
								showOnlyInvestors={membersDisabled}
							/>
						)}
					</ModalInnerWrapper>
				),
				footer: {
					component: (
						<NotifyMultipleUsersModalFooter
							onSave={this.goToHandleEmailStep}
							onCancel={this.closeModal}
						/>
					),
				},
			},
			{
				body: (
					<ModalInnerWrapper>
						<HandleEmailTemplateContainer
							templateName={templateName}
							disabled={isLoading}
						/>
					</ModalInnerWrapper>
				),
				footer: {
					leftComponent: (
						<PreviousLeftButton
							onClick={this.goToFirstStep}
							disabled={isLoading}
						/>
					),
					component: (
						<FooterRightControls>
							<TransparentButton
								tid={btnSubmitTid}
								onClick={this.submit}
								isLoading={isLoading}
							/>
							<TransparentButton
								tid="generic.form.cancel"
								textColor="midGrey"
								onClick={this.closeModal}
								disabled={isLoading}
							/>
						</FooterRightControls>
					),
				},
			},
		];
	};

	render = () => {
		const { step, usersToNotify } = this.state;
		const { isOpen, modalTitle } = this.props;

		if (!isOpen) {
			return null;
		}

		return (
			<PeopleModalProvider
				values={usersToNotify}
				onChange={this.changeUsersToNotify}
				isOpen={isOpen}
			>
				<StepsModal
					isOpen={true}
					steps={this.getSteps()}
					step={step}
					hSize="xxl"
					fullSize
					title={modalTitle}
				/>
			</PeopleModalProvider>
		);
	};
}

const mapStoreToProps = (store, ownProps) => {
	const { modalName } = ownProps;

	return {
		isOpen:
			modalName && store.modals.getIn(["activeModal", "name"]) === modalName,
		workingTemplate: store.emailTemplates.get("workingTemplate"),
	};
};

const mapActionsToProps = {
	closeModal,
	addInfoNotification,
	addErrorNotification,
};

export default connect(
	mapStoreToProps,
	mapActionsToProps,
)(NotifyMultipleUsersModalContainer);
