import React, { Component } from "react";
import { connect } from "react-redux";
import { oneOf, func } from "prop-types";
import { Map, List } from "immutable";
import Cookies from "universal-cookie";
import i18n from "@/i18n";

import SignInBankId from "../../dumb-components/credentials/middle-components/sign-in-bankid";
import SignInEmail from "../../dumb-components/credentials/middle-components/sign-in-email";

import Alert from "@/components/alert";

import {
	authBankId,
	resetCredentials,
} from "../../actions/credentials.actions";

import { validateEmail } from "../../modules/validation.module";

import {
	EMAIL_FIELD,
	PASSWORD_FIELD,
	REMEMBER_DETAILS_FIELD,
	CODE_FIELD,
} from "../../constants/credentials";
import Separator from "../../components/separator";
import { Trans } from "react-i18next";

const { t } = i18n;

class SignInContainer extends Component {
	state = {
		formData: Map(),
		hasError: Map(),
		actionBtnLoading: false,
	};

	static propTypes = {
		authMode: oneOf([0, 1]), // 0 = EMAIL / 1 = BANKID
		onChangeAuthMode: func,
		onForgotPassword: func,
		onChange: func,
	};

	componentDidMount = () => {
		this.initState(true);
		document.title = "INVONO One™ | Login med BankID";
	};

	componentDidUpdate = (prevProps) => {
		const { authMode } = this.props;

		if (prevProps.authMode !== authMode) {
			this.initState();
		}
	};

	initState = (init) => {
		const { authMode, onChangeAuthMode } = this.props;
		let rememberDetailsBankId = new Cookies().get("rememberDetailsBankId");
		let rememberDetailsEmail = new Cookies().get("rememberDetailsEmail");

		if (init && rememberDetailsEmail && authMode !== 0) {
			onChangeAuthMode(0);
			return;
		}

		rememberDetailsBankId = rememberDetailsBankId || Map();
		rememberDetailsEmail = rememberDetailsEmail || Map();

		this.setState({
			hasError: Map(),
			formData:
				authMode === 1 ? Map(rememberDetailsBankId) : Map(rememberDetailsEmail),
			actionBtnLoading: false,
		});
	};

	onChange = (fieldName, value) => {
		const { onChange } = this.props;
		let { formData } = this.state;

		if (
			formData.get("useRememberDetails") &&
			fieldName !== PASSWORD_FIELD &&
			fieldName !== CODE_FIELD
		) {
			if (formData.get("userId")) {
				new Cookies().remove("rememberDetailsBankId");
			} else {
				new Cookies().remove("rememberDetailsEmail");
			}

			formData = formData.set(REMEMBER_DETAILS_FIELD, false);
			formData = formData.remove("useRememberDetails");
			formData = formData.remove("userId");
		}

		if (fieldName === CODE_FIELD) {
			value = value.trim();
		}

		formData = formData.set(fieldName, value);

		this.setState({ formData });

		onChange?.(fieldName, value);
	};

	onSignInBankId = () => {
		const { formData } = this.state;
		const { authBankId, sessionId } = this.props;

		this.setState({ actionBtnLoading: true, signInMethod: "bankid" });

		authBankId("SIGNIN", sessionId, "SIGNIN-BANKID", formData, () => {
			this.setState({ actionBtnLoading: false });
		});
	};

	onSignInEmail = () => {
		const { formData } = this.state;
		const { authBankId, sessionId } = this.props;

		if (this.validateSignInEmail()) {
			this.setState({ actionBtnLoading: true, signInMethod: "email" });

			authBankId("SIGNIN", sessionId, "SIGNIN-BASIC", formData, () => {
				this.setState({ actionBtnLoading: false });
			});
		}
	};

	validateSignInEmail = () => {
		const { formData } = this.state;
		let hasError = Map();
		let isValid = true;

		// Validate Email
		if (!validateEmail(formData.get(EMAIL_FIELD))) {
			hasError = hasError.set(
				EMAIL_FIELD,
				List(["credentials.validation.email"]),
			);
			isValid = false;
		}

		// Check that password was entered
		if (formData.get(PASSWORD_FIELD, "").length === 0) {
			hasError = hasError.set(
				PASSWORD_FIELD,
				List(["credentials.validation.password_empty"]),
			);
			isValid = false;
		}

		this.setState({ hasError });

		return isValid;
	};

	validateCode = () => {
		const { formData } = this.state;
		let hasError = Map();
		let isValid = true;
		const codeValue = formData.get(CODE_FIELD, "");
		const hasNumbersOnly = /^\d+$/.test(codeValue);

		// Validate Code
		if (!hasNumbersOnly) {
			hasError = hasError.set(
				CODE_FIELD,
				List(["credentials.validation.code"]),
			);
			isValid = false;
		}

		this.setState({ hasError });

		return isValid;
	};

	onClickVerify = () => {
		const { formData } = this.state;
		const { authBankId, sessionId } = this.props;

		if (this.validateCode()) {
			this.setState({ actionBtnLoading: true });

			authBankId("SIGNIN", sessionId, "SIGNIN-BASIC", formData, () => {
				this.setState({ actionBtnLoading: false });
			});
		}
	};

	onCancelEmailVerify = () => {
		this.props.resetCredentials();
		this.initState(); // Needed to check details in the cookie
	};

	render = () => {
		const { formData, hasError, actionBtnLoading, signInMethod } = this.state;
		const { onForgotPassword, step, translationId, status } = this.props;

		return (
			<div className="flex flex-col">
				<h1 className="text-2xl mb-10">
					<Trans i18nKey="credentials_sign_in_title" />
				</h1>
				{translationId && (
					<Alert
						title={t(
							status === "failed"
								? "notifications.title.ERROR"
								: "notifications.title.INFO",
						)}
						message={t(translationId)}
						variant={status === "failed" ? "destructive" : undefined}
					/>
				)}
				<div className="flex flex-col">
					<SignInEmail
						onClickSignIn={this.onSignInEmail}
						onClickForgotPassword={onForgotPassword}
						onChange={this.onChange}
						onClickCancel={this.onCancelEmailVerify}
						onClickVerify={this.onClickVerify}
						hasError={hasError}
						isVerifyMode={step > 0}
						actionBtnLoading={actionBtnLoading && signInMethod === "email"}
						email={formData && formData.get(EMAIL_FIELD)}
						password={formData && formData.get(PASSWORD_FIELD)}
						code={formData && formData.get(CODE_FIELD)}
						rememberDetails={formData && formData.get(REMEMBER_DETAILS_FIELD)}
					/>
					<Separator>{t("credentials_sign_in_separator")}</Separator>
					<SignInBankId
						onClickSignIn={this.onSignInBankId}
						onChange={this.onChange}
						actionBtnLoading={actionBtnLoading && signInMethod === "bankid"}
					/>
				</div>
			</div>
		);
	};
}

function mapStoreToProps(store) {
	return {
		step: store.credentials.get("step"),
		translationId: store.credentials.get("translationId"),
		status: store.credentials.get("status"),
		sessionId: store.credentials.get("sessionId"),
	};
}

const mapActionsToProps = {
	authBankId,
	resetCredentials,
};

export default connect(mapStoreToProps, mapActionsToProps)(SignInContainer);
