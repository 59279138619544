import { useQuery } from "@tanstack/react-query";
import apiClient from "../modules/request.module.v2";
import useCompanyIdFromUrl from "./useCompanyIdFromUrl";

export default () => {
	const companyId = useCompanyIdFromUrl();

	return useQuery({
		enabled: !!companyId,
		queryKey: ["stripeTaxId", companyId],
		queryFn: () => {
			return apiClient.get(`subscriptions/tax-id`, {
				params: {
					companyId,
				},
			});
		},
	});
};
