import { useQuery } from "@tanstack/react-query";
import apiClient from "../modules/request.module.v2";
import useCompanyIdFromUrl from "./useCompanyIdFromUrl";

export default () => {
	const companyId = useCompanyIdFromUrl();

	return useQuery({
		staleTime: Infinity, // We only want to the API key once
		queryKey: ["stripePublicApiKey"],
		queryFn: () => {
			return apiClient.get(`subscriptions/public_api_key`, {
				params: { companyId },
			});
		},
	});
};
