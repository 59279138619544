import React from "react";
import PropTypes from "prop-types";
import DialogModal from "@/components/dialogModal";
import Alert from "@/components/alert";
import PaymentMethods from "./paymentMethods";
import { Trans, useTranslation } from "react-i18next";
import useDefaultPaymentMethod from "@/hooks/useDefaultPaymentMethod";
import { ChevronsDown, CreditCard } from "lucide-react";
import useCompanyIdFromUrl from "@/hooks/useCompanyIdFromUrl";
import LoadingPanel from "../loadingPanel";
import useSubscription from "@/hooks/useSubscription";
import useChangeSubscriptionPlan from "@/hooks/useChangeSubscriptionPlan";
import { SUBSCRIPTION_ESIGN_BANKID_COST } from "/shared/constants";
import SubscriptionAccountPlanCard from "../subscriptionAccountPlanCard";
import useSubscriptionPlans from "@/hooks/useSubscriptionPlans";
import Separator from "../separator";
import useDialogModal from "@/hooks/useDialogModal";
import localeFormatNumber from "/shared/helpers/number-formatter.helper";

const SubscriptionCheckoutModal = ({
	onCancel,
	confirmationMessageRenderer,
	selectedAccountPlanId,
	onPurchaseComplete,
	...props
}) => {
	const { t } = useTranslation();
	const companyId = useCompanyIdFromUrl();
	const { alert, dialogElement } = useDialogModal();

	const {
		isLoading: isLoadingDefaultPaymentMethod,
		data: defaultPaymentMethod,
	} = useDefaultPaymentMethod();
	const { data: subscriptionQuery } = useSubscription();
	const subscription = subscriptionQuery?.data;

	const {
		mutate: updateSubscriptionPlan,
		isPending: isUpdatingSubscriptionPlan,
	} = useChangeSubscriptionPlan(companyId);

	const { data: subscriptionPlansQuery } = useSubscriptionPlans();
	const subscriptionPlans = subscriptionPlansQuery?.data;

	const currentAccountPlan = [
		...subscriptionPlans.account.month,
		...subscriptionPlans.account.year,
	].find((p) => p.id === subscription.accountPlanId);

	const selectedAccountPlan = [
		...subscriptionPlans.account.month,
		...subscriptionPlans.account.year,
	].find((p) => p.id === selectedAccountPlanId);

	const confirmationMessage = confirmationMessageRenderer?.();

	const coupon = subscription?.stripeData?.discount?.coupon;

	const basePrice = selectedAccountPlan.amount / 100;
	let discountedPrice = basePrice;

	if (typeof coupon?.amount_off === "number")
		discountedPrice -= coupon.amount_off / 100;
	else if (typeof coupon?.percent_off === "number")
		discountedPrice -= (coupon.percent_off / 100) * basePrice;

	const discountedDifference = basePrice - discountedPrice;
	const isMonthly = selectedAccountPlan.interval === "month";

	const onChangePlan = async () => {
		const { prepaidAmount } = props;
		const previousAccountPlanId = subscription.accountPlanId;
		const plansToChange = [];

		selectedAccountPlanId &&
			selectedAccountPlanId !== previousAccountPlanId &&
			plansToChange.push(selectedAccountPlanId);

		// If user upgraded from FREE account and don't have any money in the pot, show pot info modal
		const showPotInfo =
			plansToChange.includes(selectedAccountPlanId) &&
			previousAccountPlanId === "freeAcount" &&
			prepaidAmount <= 0;

		updateSubscriptionPlan(
			{ planIds: plansToChange },
			{
				onSuccess: async () => {
					showPotInfo &&
						(await alert(
							t("subscriptions.balance_info.modal.title"),
							<Trans
								i18nKey="subscriptions.balance_info.modal.body"
								values={{
									singleSignaturePrice: `${SUBSCRIPTION_ESIGN_BANKID_COST} SEK`,
								}}
							/>,
							{ variant: "warning" },
						));

					onPurchaseComplete?.();
				},
			},
		);
	};

	let chevronColor = "";
	if (
		+selectedAccountPlan?.metadata.level > +currentAccountPlan.metadata.level
	) {
		chevronColor = "text-emerald-400";
	}
	if (
		+selectedAccountPlan?.metadata.level < +currentAccountPlan.metadata.level
	) {
		chevronColor = "text-red-400";
	}

	const isLoading =
		!subscriptionPlans ||
		!companyId ||
		!subscription ||
		isLoadingDefaultPaymentMethod;

	return (
		<>
			<DialogModal
				size="sm"
				title={t("subscriptions.purchase.change_subscription")}
				submitText={
					<>
						<CreditCard size={16} />
						{t("subscriptions.checkout.confirm_payment")}
					</>
				}
				onCancel={onCancel}
				onSubmit={onChangePlan}
				submitDisabled={!defaultPaymentMethod}
				isSubmitting={isUpdatingSubscriptionPlan}
				bodyRenderer={() =>
					isLoading ? (
						<LoadingPanel />
					) : (
						<div className="w-full flex gap-6 flex-col">
							{!!confirmationMessage?.length && (
								<Alert
									icon={<CreditCard />}
									title={t("generic.please_note")}
									message={confirmationMessage}
								/>
							)}
							<div className="opacity-50">
								<SubscriptionAccountPlanCard
									isCurrent
									isCurrentPlanBillingMonthly={
										currentAccountPlan.interval === "month"
									}
									plan={currentAccountPlan}
								/>
							</div>
							<Separator size="sm">
								<ChevronsDown className={chevronColor} />
							</Separator>
							<SubscriptionAccountPlanCard
								isHighlighted
								plan={selectedAccountPlan}
							/>
							<Separator size="sm">
								{t("subscriptions.payment_methods.singular")}
							</Separator>
							{!defaultPaymentMethod && (
								<Alert
									title={t(
										"subscriptions.payment_methods.payment_method_required.title",
									)}
									message={t(
										"subscriptions.payment_methods.payment_method_required.message",
									)}
									variant="destructive"
								/>
							)}
							<PaymentMethods companyId={companyId} autoOpenAddPaymentMethod />
							<Separator size="sm">{t("generic.summary")}</Separator>
							<div className="flex flex-col p-6 bg-accent/75 rounded-xl border-2 border-edge border-dotted">
								<div className="flex justify-between">
									<div>
										{t(
											`subscriptions.plans.company-account.${selectedAccountPlan.metadata.plan}`,
										)}
										{" - "}
										{isMonthly
											? t("subscriptions.plans.monthly")
											: t("subscriptions.plans.yearly")}
									</div>
									<div>{localeFormatNumber(basePrice, "currency")}</div>
								</div>
								{discountedPrice !== basePrice && (
									<div className="flex justify-between">
										<div>
											{t("subscriptions.subscribed_product.total.discount")}{" "}
											{typeof coupon?.amount_off === "number"
												? `(-${localeFormatNumber(
														coupon.amount_off / 100,
														"currency",
												  )})`
												: `(-${coupon.percent_off}%)`}
										</div>
										<div>
											-{localeFormatNumber(discountedDifference, "currency")}
										</div>
									</div>
								)}
								<div className="flex justify-between border-t-2 border-t-edge border-dotted mt-4">
									<div className="font-bold text-xl pt-4">
										{isMonthly
											? t("subscriptions.subscribed_product.total.month")
											: t("subscriptions.subscribed_product.total.year")}
									</div>
									<div className="font-bold text-xl pt-2">
										{localeFormatNumber(discountedPrice, "currency")}
									</div>
								</div>
							</div>
							<Alert
								variant="ghost"
								icon={<CreditCard />}
								title={t("generic.please_note")}
								message={t("subscriptions.confirm_purchase.question")}
							/>
						</div>
					)
				}
				{...props}
			/>
			{dialogElement}
		</>
	);
};

SubscriptionCheckoutModal.propTypes = {
	selectedAccountPlanId: PropTypes.string.isRequired,
	onCancel: DialogModal.propTypes.onCancel,
	confirmationMessageRenderer: PropTypes.func,
	onPurchaseComplete: PropTypes.func,
};

export default SubscriptionCheckoutModal;
