import React, { useEffect, useState, useMemo, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { CustomTheme } from "../../../containers/emissions/admin/custom-theme";
import { ThemeProvider } from "@mui/material/styles";
import SubscriptionSlipForm from "./subscription-slip-form";
import { useDispatch, useSelector } from "react-redux";
import {
	getAllSubscriptionSlipData,
	updateSubmission,
} from "../../actions/subscription-slip.actions";
import { useParams } from "react-router-dom";
import FullPageSpinner from "../../../containers/emissions/common/full-page-spinner";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import WhiteSection from "../../../containers/emissions/common/white-section";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { downloadDocumentPublic } from "../../../actions/documents.actions";
import emissions from "../../../services/emissions";
import CustomFileIcon from "../../../dumb-components/shared/file-icon/file-icon";
import FormAccordionEntry from "../../../containers/emissions/common/form-accordion-entry";
import EmissionDisplayValueList from "../../../containers/emissions/common/emission-display-value-list";
import EmissionDisplayValue from "../../../containers/emissions/common/emission-display-value";
import {
	displayCurrency,
	displayDate,
	displayEmissionType,
	displayInteger,
} from "../../../containers/emissions/utils/display-helpers";
import { summarize } from "../../../containers/emissions/utils/calculations";
import { emissionConsts } from "../../../constants/emissions";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EmissionDiagram from "../../../containers/emissions/common/emission-diagram";
import moment from "../../../modules/moment.module";
import { fetchSimpleUsers as publicFetchSimpleUsers } from "../../actions/usersCache.actions";
import { List } from "immutable";
import { getEmail } from "/shared/helpers/users.helpers";
import { fetchSimpleUsers } from "../../../actions/usersCache.actions";

const SubscriptionSlip = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let { companyId, emissionId, inviteeId } = useParams();
	if (!companyId && !emissionId && !inviteeId) {
		companyId = props.companyId;
		emissionId = props.emissionId;
		inviteeId = props.inviteeId;
	}
	const [loading, setLoading] = useState(true);
	const [subscriptionSlip, setSubscriptionSlip] = useState({
		displayData: { emission: null },
		submission: { finalSubmission: null },
	});
	const { displayData, submission } = subscriptionSlip;
	const { emission } = displayData;
	const language = useSelector((state) => state.i18n.language);
	const currentLang = language.substring(0, 2);
	const [expanded, setExpanded] = useState(true);
	const [expandedOverview, setExpandedOverview] = useState(true);
	const [documents, setDocuments] = useState([]);
	const readOnly = useMemo(
		() => submission.finalSubmission,
		[subscriptionSlip],
	);
	const [statistics, setStatistics] = useState();
	const [investorDocuments, setInvestorDocuments] = useState();
	const [isOverviewPage, setIsOverviewPage] = useState(true);
	const [invitee, setInvitee] = useState();
	const [errorReason, setErrorReason] = useState(null);

	const emissionContactUserId = emission?.emissionContact;
	const emissonContactPerson = useSelector((state) =>
		state.usersCache.getIn(["usersCache", emissionContactUserId]),
	);

	const {
		marketing: { diagramWhenToShare },
		common: { emissionStatus },
		public: { emissionError },
	} = emissionConsts;

	const invitedStatusMap = {
		"current-shareholder": "share-holders",
		"potential-shareholder": "investors",
		"deal-flow": "deal-flow",
	};

	const showDiagram = () => {
		const hasPermission =
			emission.marketingDiagramSettings.permissions &&
			emission.marketingDiagramSettings.permissions.includes(
				invitedStatusMap[invitee.invitedAs],
			);
		let whenToShare = true;
		if (
			emission.marketingDiagramSettings.whenToShare ===
			diagramWhenToShare.afterAchievedAmount
		) {
			whenToShare =
				statistics.achievedAmount >
				Number(emission.marketingDiagramSettings.value);
		} else if (
			emission.marketingDiagramSettings.whenToShare ===
			diagramWhenToShare.afterNumberOfDays
		) {
			const startDateAfterNumberOfDays = moment(
				emission.subscriptionStartDate,
			).add(Number(emission.marketingDiagramSettings.value), "days");
			const today = moment();
			whenToShare = startDateAfterNumberOfDays <= today;
		}
		return hasPermission && whenToShare;
	};

	const getData = useCallback(async () => {
		setLoading(true);
		setErrorReason(null);
		try {
			if (companyId && emissionId && inviteeId) {
				const data = await dispatch(
					getAllSubscriptionSlipData(
						companyId,
						emissionId,
						inviteeId,
						currentLang,
					),
				);

				const emissionClosed =
					moment() >
						moment(data.displayData.emission.subscriptionEndDate).add(
							1,
							"days",
						) ||
					data.displayData.emission.status === emissionStatus.completed ||
					data.displayData.emission.status === emissionStatus.registered;
				const emissionCancelled =
					data.displayData.emission.status === emissionStatus.cancelled;

				if (!props.isInvestorPage && emissionClosed) {
					setErrorReason(emissionError.closed);
				} else if (!props.isInvestorPage && emissionCancelled) {
					setErrorReason(emissionError.cancelled);
				}

				setSubscriptionSlip(data);
			}

			setStatistics(
				await emissions.getEmissionStatistics(companyId, emissionId),
			);
			const invitee = await emissions.getSingleInvitee(
				companyId,
				emissionId,
				inviteeId,
			);
			setInvitee(invitee);
		} catch {
			setErrorReason(emissionError.notFound);
		} finally {
			setLoading(false);
		}
		if (props.isInvestorPage) {
			setInvestorDocuments(
				await emissions.getDocumentsForInvitee(
					companyId,
					emissionId,
					inviteeId,
					currentLang,
				),
			);
		}
	}, [companyId, emissionId, inviteeId, currentLang, props.isInvestorPage]);

	// display error boundary with descriptive message
	if (errorReason) {
		throw new Error(errorReason);
	}

	useEffect(() => {
		const debounce = setTimeout(() => {
			async function getMarketingFiles() {
				const marketingFiles = await emissions.getMarketingFiles(
					companyId,
					emissionId,
				);
				setDocuments(Object.values(marketingFiles));
			}

			getMarketingFiles();
			getData();
		}, 0);

		return () => clearTimeout(debounce);
	}, [companyId, emissionId, inviteeId, currentLang]);

	useEffect(() => {
		if (IN_PUBLIC) {
			dispatch(publicFetchSimpleUsers(List([emissionContactUserId])));
		} else {
			dispatch(fetchSimpleUsers(List([emissionContactUserId])));
		}
	}, [emissionContactUserId]);

	function displayOverviewPage() {
		setIsOverviewPage(true);
		getData();
	}

	function displayFormulaPage() {
		setIsOverviewPage(false);
	}

	const style = `
	html {
		font-size: 10px;
	}
	body {
		font-size: 13px;
		line-height: 1.42857143;
	}`;

	if (loading) {
		return <FullPageSpinner text={t("emissions.subscription-form.loading")} />;
	}

	const OverviewLink = () => (
		<Typography
			variant={"subtitle2"}
			sx={{
				display: "flex",
				alignItems: "center",
				cursor: "pointer",
				"&:hover": {
					textDecoration: "underline",
				},
			}}
			onClick={() => props.displayEmissionList()}
		>
			<ChevronLeftIcon sx={{ mr: 1 }} />{" "}
			{t("emissions.subscription-form.to-emission-list")}
		</Typography>
	);

	return (
		<>
			<ThemeProvider theme={CustomTheme}>
				{isOverviewPage ? (
					<>
						<WhiteSection
							sx={{ py: 0, px: 0, height: "100%" }}
							name="subscription-slip"
						>
							<Grid container>
								<Grid item xs={12} md={6}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											height: "100%",
										}}
									>
										<Typography
											variant={"subtitle2"}
											sx={{ my: 1, color: "secondary.400", fontWeight: "400" }}
										>
											{emission.name}
										</Typography>
										<Typography variant={"h2"} sx={{ my: 1 }}>
											{t(
												"public.subscription-slip.container.invitation-to-subscribe.header",
												{
													companyName: displayData.company.name,
												},
											)}
										</Typography>
										<Typography variant={"body2"} sx={{ my: 1 }}>
											{t(
												"public.subscription-slip.container.invitation-to-subscribe.desc",
											)}
										</Typography>
										<Box
											sx={{
												pt: 6,
												flex: 1,
												display: "flex",
												alignItems: "flex-end",
											}}
										>
											{readOnly ? (
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: "1rem",
													}}
												>
													<div
														style={{
															display: "flex",
															gap: "10px",
															alignItems: "center",
														}}
													>
														<Chip
															icon={<CheckIcon />}
															label={t(
																submission.signed
																	? "public.subscription-slip.shares-submitted.signed"
																	: "public.subscription-slip.shares-submitted.not-signed",
															)}
															sx={{
																color: submission.signed
																	? "success.dark"
																	: "black",
																bgcolor: submission.signed
																	? "success.100"
																	: "#fdd835",
																"& .MuiChip-icon": {
																	color: submission.signed
																		? "success.dark"
																		: "black",
																},
															}}
														/>
														{/* Hard-code to document in Swedish, since the backend is currently hardcoded to that language as well:
																https://app.asana.com/0/1206149013990520/1207977434457126/f
														*/}
														{!submission.signed && (
															<a
																href={`/public/${companyId}/documents/${submission.documentId_sv}/${inviteeId}/sign`}
															>
																{t("public.subscription-slip.click_to_sign")}
															</a>
														)}
													</div>
													<Box sx={{ display: "flex", gap: "5rem" }}>
														{props.displayEmissionList && <OverviewLink />}
														<Typography
															variant={"subtitle2"}
															sx={{
																display: "flex",
																alignItems: "center",
																cursor: "pointer",
																textAlign: "end",
																"&:hover": {
																	textDecoration: "underline",
																},
															}}
															onClick={() => displayFormulaPage()}
														>
															{t("emissions.subscription-form.to-formula")}{" "}
															<ChevronRightIcon sx={{ ml: 1 }} />
														</Typography>
													</Box>
												</Box>
											) : (
												<Box>
													<Button
														variant={"contained"}
														onClick={() => displayFormulaPage()}
														sx={{ width: "fit-content", mb: 2 }}
													>
														<Typography variant={"h5"}>
															{t(
																"public.subscription-slip.container.subscribe-for-shares",
															)}
														</Typography>
													</Button>
													{props.displayEmissionList && <OverviewLink />}
												</Box>
											)}
										</Box>
									</Box>
								</Grid>
								<Grid
									item
									md={6}
									sx={{
										display: { xs: "none", md: "flex" },
										justifyContent: "flex-end",
										alignItems: "flex-end",
									}}
								>
									<Box sx={{ display: "flex", alignItems: "flex-end" }}>
										<Box
											sx={{
												mx: "24px",
												width: "48px",
												height: "160px",
												backgroundColor: "secondary.100",
											}}
										/>
										<Box
											sx={{
												mx: "24px",
												width: "48px",
												height: "250px",
												backgroundColor: "secondary.100",
											}}
										/>
										<Box
											sx={{
												mx: "24px",
												width: "48px",
												height: "86px",
												backgroundColor: "secondary.100",
											}}
										/>
									</Box>
								</Grid>
							</Grid>
						</WhiteSection>
						<Box
							sx={{
								mt: 11,
								mb: 2,
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									flexWrap: "wrap",
									gap: 2,
								}}
							>
								{showDiagram() && (
									<Box sx={{ backgroundColor: "#fff", padding: 2, flex: 1 }}>
										<Typography sx={{ my: "12px", mx: "24px" }} variant={"h5"}>
											{t("emissions.subscriptions.achieved-amount")}
										</Typography>
										<EmissionDiagram
											prefix={t("emissions.subscriptions.generic-of")}
											suffix="SEK"
											emission={emission}
											statistics={statistics}
										/>
									</Box>
								)}
								<Box sx={{ backgroundColor: "#fff", padding: 2, flex: 1 }}>
									<Typography sx={{ my: "12px", mx: "24px" }} variant={"h5"}>
										{t("emission.company_contact", {
											company: displayData.company.name,
										})}
									</Typography>
									{emissonContactPerson && (
										<Typography
											variant="body2"
											sx={{ fontSize: "13px", mx: "24px" }}
										>
											{emissonContactPerson.get("name")}
											<br />
											{t("emission.company_contact.email", {
												email: getEmail(
													emissonContactPerson?.toJS(),
													companyId,
												),
											})}
											<br />
											{t("emission.company_contact.phone", {
												phone: emissonContactPerson.get("phone"),
											})}
										</Typography>
									)}
									<Typography
										variant="body2"
										sx={{ fontSize: "13px", mt: 3, mx: "24px" }}
									>
										{t("emission.company_contact.text")}
									</Typography>
								</Box>
							</Box>
							{props.isInvestorPage && (
								<SignedDocuments
									companyId={companyId}
									investorDocuments={investorDocuments}
									allocationType={emission.allocationType}
								/>
							)}
							<Accordion
								expanded={expandedOverview}
								elevation={0}
								disableGutters
								sx={{ display: "flex", flexDirection: "column" }}
								onChange={(_, exp) => setExpandedOverview(exp)}
							>
								<AccordionSummary
									expandIcon={<KeyboardArrowDownIcon />}
									sx={{
										borderBottom: 1,
										borderColor: "grey.100",
										display: "flex",
									}}
								>
									<Typography sx={{ my: "12px", mx: "24px" }} variant={"h5"}>
										{t("public.subscription-slip.emission-offering")}
									</Typography>
								</AccordionSummary>
								<AccordionDetails
									sx={{
										"&.MuiButtonBase-root:hover": {
											cursor: "default",
										},
										display: "flex",
										my: "8px",
									}}
								>
									<OfferingOverview {...subscriptionSlip} />
								</AccordionDetails>
							</Accordion>
							{documents.length ? (
								<Accordion
									expanded={expanded}
									elevation={0}
									disableGutters
									sx={{ display: "flex", flexDirection: "column" }}
									onChange={(_, exp) => setExpanded(exp)}
								>
									<AccordionSummary
										expandIcon={<KeyboardArrowDownIcon />}
										sx={{
											borderBottom: 1,
											borderColor: "grey.100",
											display: "flex",
										}}
									>
										<Typography sx={{ my: "12px", mx: "24px" }} variant={"h5"}>
											{t("meetings.public.documents.title")}
										</Typography>
									</AccordionSummary>
									<Box sx={{ display: "flex", flexWrap: "wrap" }}>
										{documents
											.filter((file) =>
												file.permissions.includes(
													invitedStatusMap[invitee.invitedAs],
												),
											)
											.map(({ id, name }) => {
												return (
													<AccordionDetails
														key={id}
														sx={{
															"&.MuiButtonBase-root:hover": {
																cursor: "default",
															},
															display: "flex",
															my: "8px",
														}}
													>
														<FilePreview
															companyId={companyId}
															id={id}
															name={name}
														/>
													</AccordionDetails>
												);
											})}
									</Box>
								</Accordion>
							) : null}
						</Box>
					</>
				) : (
					<>
						<style>{style}</style>
						<Box
							className="subscription-slip-form"
							sx={{
								height: "100%",
							}}
						>
							<SubscriptionSlipForm
								statistics={statistics}
								subscriptionSlip={subscriptionSlip}
								onSubmit={async (values) => {
									await dispatch(
										updateSubmission(companyId, emissionId, inviteeId, {
											...values,
											signSubmissionNote: true,
										}),
									);
								}}
								displayOverviewPage={displayOverviewPage}
								invitee={invitee}
							/>
						</Box>
						<div></div>
					</>
				)}
			</ThemeProvider>
		</>
	);
};

const OpenDocuments = (companyId, docId) => {
	downloadDocumentPublic({
		documentId: docId,
		companyId: companyId,
		openInViewer: true,
		getMergedVersion: false,
		getSignedVersion: false,
	})();
};

const FilePreviewBox = ({ name, type = "pdf", onClick }) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				border: "solid 1px #E0E0E0",
				borderRadius: "4px",
				py: "13px",
				px: "16px",
			}}
		>
			<Box sx={{ width: "34px", height: "42px", mr: "16px" }}>
				<CustomFileIcon ext={type} />
			</Box>
			<Typography
				onClick={onClick}
				sx={{ cursor: "pointer" }}
				variant={"body2"}
			>
				{name}
			</Typography>
		</Box>
	);
};

const FilePreview = ({ companyId, id, name }) => {
	const type = name.split(".").pop().toLowerCase();

	return (
		<FilePreviewBox
			name={name}
			type={type}
			onClick={() => {
				OpenDocuments(companyId, id);
			}}
		/>
	);
};

const SignedDocumentPart = ({ label, body }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				backgroundColor: "#fff",
				padding: 5,
				pt: 2,
			}}
		>
			<Typography
				variant={"overline"}
				color="secondary.main"
				sx={{ textTransform: "uppercase" }}
			>
				{label}
			</Typography>
			{body}
		</Box>
	);
};

const SignedDocuments = ({ investorDocuments, allocationType, companyId }) => {
	const { t } = useTranslation();
	const {
		common: { allocationTypes },
	} = emissionConsts;

	const isDecisionAllocation = allocationType === allocationTypes.decision;

	function openDocument(fileId) {
		downloadDocumentPublic({
			documentId: fileId,
			companyId: companyId,
			openInViewer: true,
			getMergedVersion: false,
			getSignedVersion: false,
		})();
	}

	return (
		<Box
			sx={{
				display: "grid",
				gap: "16px",
				gridTemplateColumns: {
					md: isDecisionAllocation ? "1fr 1fr 1fr" : "1fr 1fr",
					xs: "1fr",
				},
			}}
		>
			<SignedDocumentPart
				label={t("emission.submission.pdf-note-filename")}
				body={
					investorDocuments?.submissionNote ? (
						<FilePreviewBox
							name={investorDocuments.submissionNote.name}
							onClick={() => {
								openDocument(investorDocuments.submissionNote.fileId);
							}}
						/>
					) : (
						<Typography variant={"body2"}>
							{t("emissions.subscription-form.pdf_notes.submission")}
						</Typography>
					)
				}
			/>
			{isDecisionAllocation && (
				<SignedDocumentPart
					label={t("emission.settlement.pdf-note-filename")}
					body={
						investorDocuments?.settlementNotes?.length ? (
							investorDocuments.settlementNotes.map((note) => (
								<FilePreviewBox
									key={note.fileId}
									name={note.name}
									onClick={() => {
										openDocument(note.fileId);
									}}
								/>
							))
						) : (
							<Typography variant={"body2"}>
								{t("emissions.subscription-form.pdf_notes.settlement")}
							</Typography>
						)
					}
				/>
			)}
			<SignedDocumentPart
				label={t("emission.receipt.pdf-filename")}
				body={
					investorDocuments?.receipts?.length ? (
						investorDocuments.receipts.map((receipt) => (
							<FilePreviewBox
								key={receipt.fileId}
								name={receipt.name}
								onClick={() => {
									openDocument(receipt.fileId);
								}}
							/>
						))
					) : (
						<Typography variant={"body2"}>
							{t("emissions.subscription-form.pdf_notes.receipt")}
						</Typography>
					)
				}
			/>
		</Box>
	);
};

const EmissionDisplayValueListWrapper = ({ children }) => (
	<Box
		sx={{
			width: "100%",
			"> div": {
				mt: 0,
			},
		}}
	>
		<EmissionDisplayValueList>{children}</EmissionDisplayValueList>
	</Box>
);

const EmissionDisplayValueWrapper = ({ label = "", value = "" }) => (
	<Box
		sx={{
			"p:last-child": {
				justifyContent: "flex-end",
				textAlign: "end",
			},
		}}
	>
		<EmissionDisplayValue label={label} value={value} />
	</Box>
);

const OfferingOverview = ({ displayData: { emission } }) => {
	const { t } = useTranslation();
	const calculations = summarize(emission);
	const {
		common: { allocationTypes, newShareCountTypes },
	} = emissionConsts;

	return (
		<Grid
			container
			rowSpacing={3}
			columnSpacing={3}
			justifyContent="space-between"
		>
			<Grid item xs={12} md={6} lg={5}>
				<FormAccordionEntry
					header={t("emissions.header.basic-information")}
					disableClose
				>
					<EmissionDisplayValueListWrapper>
						<EmissionDisplayValueWrapper
							label={"emissions.form-field.emission-type"}
							value={t(displayEmissionType(emission))}
						/>
						<EmissionDisplayValueWrapper
							label={"emissions.form-field.current-share-count"}
							value={`${displayInteger(emission.currentShareCount)} ${t(
								"public.subscription-slip.container.shares",
							)}`}
						/>
						<EmissionDisplayValueWrapper
							label={
								emission.newShareCountType === newShareCountTypes.range
									? "emissions.subscription-form.new-shares"
									: "emissions.pdf.new-share-count"
							}
							value={`${calculations.sharesMinMax} ${t(
								"public.subscription-slip.container.shares",
							)}`}
						/>
						<EmissionDisplayValueWrapper
							label={"emissions.form-field.price-per-share"}
							value={`${displayCurrency(emission.pricePerShare)} ${t(
								"public.subscription-slip.container.per-share",
							)}`}
						/>
						<EmissionDisplayValueWrapper
							label={"emissions.form-field.dilution-effect"}
							value={`${calculations.dilutionEffect}%`}
						/>
						<EmissionDisplayValueWrapper
							label={"emissions.form-field.quote-value"}
							value={`${displayCurrency(emission.quoteValue)} SEK`}
						/>
						<EmissionDisplayValueWrapper
							label={"emissions.form-field.share-capital-raise"}
							value={`${calculations.shareCapitalRaise} SEK`}
						/>
					</EmissionDisplayValueListWrapper>
				</FormAccordionEntry>
			</Grid>
			<Grid item xs={12} md={6} lg={5}>
				<Grid container rowSpacing={3}>
					<Grid item xs={12}>
						<FormAccordionEntry
							header={t("emissions.header.allocation")}
							disableClose
						>
							<EmissionDisplayValueListWrapper>
								<EmissionDisplayValueWrapper
									label={"public.subscription-slip.allocation-by"}
									value={t(
										emission.allocationType === allocationTypes.payment
											? "emissions.form-field.allocation-type.options.payment"
											: "emissions.form-field.allocation-type.options.decision",
									)}
								/>
								<EmissionDisplayValueWrapper
									label={"emissions.form-field.over-subscription-type"}
									value={t(
										`emissions.form-field.over-subscription-type.options.${emission.overSubscriptionType}`,
									)}
								/>
								<EmissionDisplayValueWrapper
									label={"emissions.pdf.subscription-date"}
									value={`${displayDate(
										emission.subscriptionStartDate,
									)} - ${displayDate(emission.subscriptionEndDate)}`}
								/>
							</EmissionDisplayValueListWrapper>
						</FormAccordionEntry>
					</Grid>
					<Grid item xs={12}>
						<FormAccordionEntry
							header={t("emissions.header.payment")}
							disableClose
						>
							<EmissionDisplayValueListWrapper>
								<EmissionDisplayValueWrapper
									label={"emissions.form-field.payment-type"}
									value={t(
										`emissions.form-field.payment-type.options.${emission.paymentType}`,
									)}
								/>
								<EmissionDisplayValueWrapper
									label={"emissions.subscription-form.payment-due-date"}
									value={displayDate(emission.paymentDueDate)}
								/>
							</EmissionDisplayValueListWrapper>
						</FormAccordionEntry>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SubscriptionSlip;
