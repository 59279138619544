import { fromJS, Map } from "immutable";
import req from "../modules/request.module";
import {
	TRANSACTION_SAVE_TEMP,
	TRANSACTION_SAVE_TEMP_START,
	TRANSACTION_FETCH_TEMP,
	TRANSACTION_UPDATE_TEMP,
	TRANSACTION_TEMP_DIRTY,
	TRANSACTION_FETCH,
	TRANSACTION_LIST,
	TRANSACTION_CREATE,
	NOTIFY_ADD_MESSAGE,
	NOTIFY_FAKE_LOADING_START,
	NOTIFY_FAKE_LOADING_END,
	TRANSACTION_INITIAL_EMISSION_LEVELING,
	TRANSACTION_INITIAL_ADJUSTMENT_TRANSACTION,
	TRANSACTION_CLEAR_TEMPORARY_TRANSACTIONS,
	TRANSACTION_CLEAR_TEMPORARY_TRANSACTION,
} from "./types";
import { addErrorNotification, addInfoNotification } from "./notify.actions";
import {
	EVENT_TYPE_SHARES_TRANSACTION_CREATE,
	EVENT_TYPE_SHARES_TRANSACTION_DELETE,
} from "/shared/constants";
import { setLiveRequest, resetLiveRequest } from "./live-update.actions";
import documentsHelper from "../components/helpers/documents.helper";
import history from "../interfaces/history";

export function updateTransaction(transactionType, transaction) {
	return function (dispatch) {
		dispatch({
			type: TRANSACTION_UPDATE_TEMP,
			payload: transaction,
		});
		dispatch({
			type: TRANSACTION_TEMP_DIRTY,
		});
	};
}

export function fetchTemporaryTransaction(transactionType) {
	return function (dispatch) {
		return req
			.get(`/shares/transaction/temp/${transactionType}`, { onlyLatest: true })
			.then((response) => {
				if (response.data) {
					// will return null if the temp do not exist.
					dispatch({
						type: TRANSACTION_FETCH_TEMP,
						payload: fromJS(response.data),
					});
				}
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes("onlyLatest:true")) {
					console.log(e);
				}
			});
	};
}

export function saveTemporaryTransaction(
	transactionType,
	transaction,
	callback,
) {
	return function (dispatch) {
		dispatch({
			type: TRANSACTION_SAVE_TEMP_START,
		});
		return req
			.put(`/shares/transaction/temp/${transactionType}`, transaction, {
				onlyLatest: true,
			})
			.then((response) => {
				dispatch({
					type: TRANSACTION_SAVE_TEMP,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes("onlyLatest:true")) {
					console.log(e);
				}
			});
	};
}

export function cancelTemporaryTransaction(transactionType, callback) {
	return function (dispatch) {
		return req
			.delete(`/shares/transaction/temp/${transactionType}`)
			.then(() => {
				dispatch({
					type: TRANSACTION_CLEAR_TEMPORARY_TRANSACTION,
					payload: transactionType,
				});

				const paths = window.location.pathname.split("/");
				paths.pop();
				history.replace(paths.join("/"));
				callback && callback();
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function cancelTemporaryTransactions() {
	return function (dispatch) {
		return req
			.delete(`/shares/transaction/temp`)
			.then(() => {
				dispatch({
					type: TRANSACTION_CLEAR_TEMPORARY_TRANSACTIONS,
					payload: Map(),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function createTransaction(transaction, callback, errorCallback) {
	return function (dispatch) {
		dispatch({
			type: NOTIFY_FAKE_LOADING_START,
			payload: "processing_transaction",
		});

		return req
			.post("/shares/transactions", transaction, { onlyLatest: true })
			.then((response) => {
				setTimeout(() => {
					dispatch({
						type: NOTIFY_FAKE_LOADING_END,
					});
					dispatch(
						addInfoNotification({
							tid: "transaction_registered_successfull",
						}),
					);
					dispatch({
						type: TRANSACTION_CREATE,
						payload: fromJS(response.data),
					});
					dispatch(clearTemporaryTransaction());
					callback && callback();
				}, 1500);
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes("onlyLatest:true")) {
					if (
						e.response.data &&
						e.response.data.data &&
						e.response.data.data.divisionErrors
					) {
						transaction = fromJS(transaction).setIn(
							["handlerData", "shareDiff"],
							fromJS(e.response.data.data.divisionErrors.details),
						);
						transaction = transaction.set(
							"needCompanyAsInvestment",
							e.response.data.data.divisionErrors.needCompanyAsInvestment,
						);
						dispatch(
							saveTemporaryTransaction(
								transaction.get("type"),
								transaction.toJS(),
							),
						);
					}

					if (
						e.response.data &&
						e.response.data.data &&
						e.response.data.data.investments
					) {
						transaction = fromJS(transaction).setIn(
							["handlerData", "investments"],
							fromJS(e.response.data.data.investments),
						);
						dispatch(
							saveTemporaryTransaction(
								transaction.get("type"),
								transaction.toJS(),
							),
						);
					}

					dispatch({
						type: NOTIFY_FAKE_LOADING_END,
					});

					errorCallback && errorCallback();
				}
			});
	};
}

function clearTemporaryTransaction() {
	return {
		type: TRANSACTION_CLEAR_TEMPORARY_TRANSACTIONS,
		payload: Map(),
	};
}

export function checkDivisionErrors(transaction, callback) {
	return function (dispatch) {
		const toolData = {
			after: transaction.getIn(["handlerData", "after"]),
			before: transaction.getIn(["handlerData", "before"]),
		};

		return req
			.post("/shares/tools/", { toolData, toolType: "CHECK-DIVISION-ERRORS" })
			.then((response) => {
				if (response.data && response.data.details) {
					const shareDiff = fromJS(response.data.details);
					transaction = transaction.setIn(
						["handlerData", "shareDiff"],
						shareDiff,
					);
					dispatch(
						saveTemporaryTransaction(
							transaction.get("type"),
							transaction.toJS(),
						),
					);
					callback && callback();
				} else {
					dispatch(
						addInfoNotification({
							tid: "notification.no_division_errors",
						}),
					);
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(transactionError("Failed to check division errors"));
			});
	};
}

export function fetchLatestTransaction() {
	return function (dispatch) {
		return req
			.get("/shares/transactions?limit=1")
			.then((response) => {
				dispatch({
					type: TRANSACTION_FETCH,
					payload: fromJS(response.data[0]),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function listTransactionsForSharebook() {
	return function (dispatch) {
		return req
			.get("/shares/transactions/sharebook")
			.then((response) => {
				dispatch({ type: TRANSACTION_LIST, payload: fromJS(response.data) });
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function resetAdjustmentTransaction() {
	return {
		type: TRANSACTION_INITIAL_ADJUSTMENT_TRANSACTION,
		payload: null,
	};
}

export function resetEmissionLevelingTransaction() {
	return {
		type: TRANSACTION_INITIAL_EMISSION_LEVELING,
		payload: null,
	};
}

function transactionError(msg) {
	return {
		type: NOTIFY_ADD_MESSAGE,
		payload: {
			title: "Error",
			text: msg,
			className: "alert-danger",
		},
	};
}

export function deleteLastTransaction(callback) {
	return function (dispatch) {
		return req
			.delete("/shares/transactions/last")
			.then((response) => {
				dispatch({
					type: TRANSACTION_FETCH,
					payload: fromJS(response.data[0]),
				});
				callback && callback();
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

//Move this to where apporperiate
export function importEmissionExcel(file, callback, errorCallback) {
	return function (dispatch) {
		const valid = documentsHelper.validateFileExtension(file, ["xls", "xlsx"]);

		if (!valid) {
			dispatch(
				addErrorNotification({
					tid: "shares.investors.import.error.invalid_file_extension",
				}),
			);
			errorCallback();
			return;
		}

		const fileForm = new FormData();
		fileForm.append("file", file);

		req
			.post(`/shares/excel/parse/emission`, fileForm)
			.then((response) => {
				dispatch({
					type: TRANSACTION_UPDATE_TEMP,
					payload: fromJS(response.data),
				});
				callback && callback();
			})
			.catch((e) => {
				const errorData = e.response.data.data;
				errorCallback(errorData);
			});
	};
}

export function socketEventTransactions(eventObj) {
	const { eventName, objId, metadata } = eventObj;

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_SHARES_TRANSACTION_CREATE:
			case EVENT_TYPE_SHARES_TRANSACTION_DELETE: {
				dispatch(
					setLiveRequest(["transactions", eventName], {
						refresh: true,
						objId,
						metadata,
					}),
				);
				dispatch(resetLiveRequest(["transactions", eventName]));
				break;
			}
			default:
				dispatch({ type: "INVESTORS_NULL", payload: null });
		}
	};
}
