import React from "react";
import UserAvatar from "@/components/userAvatar";

const ProfileImageRenderer = ({ data }) => {
	return (
		<div className="flex h-full items-center">
			<UserAvatar userId={data.id} userName={data.name} />
		</div>
	);
};

export { ProfileImageRenderer };
