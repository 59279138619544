import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { func, bool, string, object, number } from "prop-types";
import { Padding } from "styled-components-spacing";
import Grid from "styled-components-grid";
import Panel from "../shared/panel/panel";
import Label from "../shared/label/label";

import { Button } from "@/components/ui/button";
import CouponField from "@/components/subscriptions/couponField";
import { useTranslation } from "react-i18next";
import useSubscription from "@/hooks/useSubscription";
import SubscriptionPlanSelector from "@/components/subscriptionPlanSelector";
import SubscriptionBillingSummary from "@/components/subscriptions/subscriptionBillingSummary";

const PlansPanel = (props) => {
	const {
		selectedAccountPlanId,
		selectedStoragePlanId,
		subscribedAccountPlan,
		subscribedStoragePlan,
		isFreeWithStoragePlan,
		isLoading,
		disabled,
		onSelectAccountPlan,
		onClickPurchase,
		companyDueForCancellation,
	} = props;
	const { t } = useTranslation();
	const { data: subscriptionRequest } = useSubscription();
	const subscription = subscriptionRequest?.data;

	const hasChangedStoragePlan =
		selectedStoragePlanId !== subscribedStoragePlan?.id;
	const hasChangedAccountPlan =
		selectedAccountPlanId !== subscribedAccountPlan?.id;
	const isFreePlan = subscribedAccountPlan?.id === "freeAccount";

	return (
		<Panel mode="light" title="subscriptions.select_product" marginBottom>
			<Padding all={3}>
				<div className="flex flex-col p-4 gap-6">
					<SubscriptionBillingSummary />
					<SubscriptionPlanSelector
						onChange={(selectedAccountPlanId) => {
							onSelectAccountPlan(selectedAccountPlanId);
						}}
					/>
				</div>
				<Grid>
					<Grid.Unit size={1}>
						<div className="flex flex-col items-baseline gap-4 p-2">
							<div className="flex w-full gap-4 justify-between px-2">
								<div className="flex-1">
									<Label tid="subscriptions.coupon">
										<CouponField disabled={disabled} />
									</Label>
								</div>
								<div className="flex-1 flex items-end">
									<Button
										className="w-full"
										size="lg"
										isLoading={isLoading}
										onClick={onClickPurchase}
										disabled={
											disabled ||
											companyDueForCancellation ||
											selectedAccountPlanId === subscription?.accountPlanId ||
											companyDueForCancellation ||
											isFreeWithStoragePlan ||
											!(hasChangedAccountPlan || hasChangedStoragePlan)
										}
									>
										{t(
											isFreePlan
												? "subscriptions.checkout.go_to_payment"
												: selectedAccountPlanId === "freeAccount"
												? "subscriptions.button.cancel_subscription"
												: "subscriptions.purchase.change_subscription",
										)}
									</Button>
								</div>
							</div>
						</div>
					</Grid.Unit>
				</Grid>
			</Padding>
		</Panel>
	);
};

PlansPanel.defaultProps = {
	isLoading: false,
};

PlansPanel.propTypes = {
	plans: ImmutablePropTypes.list,
	selectedPlan: string,
	onSelectPlan: func,
	onClickPurchase: func,
	isProductLoading: bool,
	willChangePlan: bool,
	companyDueForCancellation: bool,
	onSaveSubscription: func,
	isFreeWithStoragePlan: bool,
	willHaveFreeAccountPlan: bool,
	documentsSpace: number,
	usedStorageSpace: number,
	selectedAccountPlan: object,
};

export default PlansPanel;
